import React from "react";

import MultiForm from "../../MultiForm/MultiForm";
import style from "./PatientCreatModal.module.css";

export default function PatientCreatModal() {


  return (
    <>
      <div className={style.overlay}>
        <div className={style.modal}>
          <div className={style.modal_content}>
              <MultiForm />

          </div>
        </div>
      </div>
    </>
  );
}
