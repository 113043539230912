import { Fragment } from 'react'

import style from './OrdonanceParaMedicaux.module.css'

import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';


export default function OrdonanceParaMedicaux({setPage}) {
  return (
    <Fragment>
      <div className={style.head}>
        <div className={style.search_space}>
          <div className={style.search}>
            <SearchIcon style={{fontSize: "30px"}} className={style.serachicon} />
            <input type="text" placeholder="Ajouter un médicament ou un modèle" />
          </div>
        </div>
        {/* <div className={style.listes}>
          <div className={style.liste}>
            <div className={style.text}>
              <h4>BIOLOGIE 1g biologie (lorem apsum)</h4>
              <p>1 comprimé 3 fois par jour</p>
            </div>
            <div className={style.icon} >
              <MoreVertIcon style={{fontSize: "24px"}} />
            </div>
          </div>
        </div> */}
      </div>
      <div className={style.buttons}>
        <button onClick={() => setPage(0)}>Annuler</button>
        <button>Valider</button>
      </div>
    </Fragment>
  )
}
