import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';


import style from './PreviousVal.module.css'


import { getPrevDonnePatient } from '../../../../redux/features/DonneeSuivie/DonneeSuivieSlice'
import { useState } from 'react';
import moment from 'moment';
import { Fragment } from 'react';
import { useParams } from 'react-router-dom';


export default function PreviousVal({ id }) {


    const { previousdata } = useSelector((state) => state.donnesuivie)
    const dispatch = useDispatch()


    const params = useParams()
    const patient_pk = params.patientid


    useEffect(() => {
        dispatch(getPrevDonnePatient(patient_pk))
    },  [dispatch]);
    
    
    const displayFond = () => {
        if (previousdata.length !== 0) {
            if (previousdata !== undefined) {
                const fond = previousdata.find(elem => elem.nomDonnee.id === id)
                if (fond) {
                    return (
                        <div className={style.subform}>
                            <p>
                                {fond.valeur} {fond.nomDonnee.unite}<br />({moment(fond.date).format('D-M-Y')})</p>
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <p><br /></p>
                        </div>
                    )
                }
            }
        }
    }


  return (
    <Fragment>
        {displayFond()}
    </Fragment>
  )
}
