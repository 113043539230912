
import { red } from '@mui/material/colors';
import React, { useRef } from 'react'
import { useState } from 'react';
import { Fragment } from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import GridCloseIcon from '../../../assets/images/close.svg'
import { sharePrescription } from '../../../redux/features/Prescription/PrescriptionSlice';
import { ShareReportWithPatient } from '../../../redux/features/Report/ReportSlice';
import useOutSideClick from '../../Agenda/TableTime/Utils';


import style from './ShareModal.module.css'

export default function ShareModal({ setModal, ordo }) {


    const popover = useRef();
    useOutSideClick(popover, () => setModal(false));

    const params = useParams()
    const patient = params.patientid
    const dispatch = useDispatch()

    const [validation, setValidation] = useState("")



    const presobj = {
        "consultation" : ordo.consultation,
        "patient" : patient,
        "prescription" : ordo.id
    }

    const handleClickShare = () => {
        if (ordo.type === "ordonance") {
            dispatch(sharePrescription(presobj)).then((e) => {
                setModal(false)
            })
        } else if (ordo.type === "compte rendu") {
            const report = {
                "compteRendu" : ordo.id,
                "patient" : patient
            }
            dispatch(ShareReportWithPatient(report)).then((e) => {
                setModal(false)
            })
        } else if (ordo.type === "ordonance (p)") {
            setValidation('Cette ordonnance a déja etais partagé')
        }
        else if (ordo.type === "compte rendu (p)") {
            setValidation('Ce compterenu a déja etais partagé')
        }
    }


    return (
        <Fragment>
            <div className={style.overlay}>
                <div ref={popover} className={style.modalContainer}>
                    <div className="img">
                        <img
                            className={style.closeBtn}
                            alt="close "
                            src={GridCloseIcon}
                            onClick={() => setModal(false)}
                            />
                    </div>
                    <div className={style.paragraph}>
                        {validation ? 
                            <p style={{color : '#c70000'}}>{validation}</p>
                            :
                            <p>Êtes-vous sûr de vouloir partager ce document ?</p>
                        }
                        <div className={style.buttons}>
                            <button
                            onClick={() => setModal(false)}
                            >Non</button>
                            <button
                            onClick={handleClickShare}
                            >Oui</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
