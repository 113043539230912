import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'


import { getPatientDetails,updatePatientDetails ,reset } from '../../../redux/features/Patient/patientSlice'
import PatientOutletHeader from '../PatientOutletHeader/PatientOutletHeader'


import style from './Cordonnees.module.css'

export default function Cordonnees() {


  const { cordonnees } = useSelector((state) => state.patients);
  const { user } = useSelector((state) => state.auth);


  
  let disabled = true
  if (cordonnees.user === user.id) {
    disabled = false
  }



  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const id = params.patientid


  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    sexe: "",
    nom_de_naissance: "",
    date_de_naissance: "2000-01-01",
    pays_de_naissance: "",
    ville_de_naissance: "",
    email: "",
    phone_number: "",
  })

  const [validation, setValidation] = useState('')

  useEffect(() => {
    if(cordonnees.length !== 0){
        setFormData(cordonnees);
    }
    }, [cordonnees])




  useEffect(() => {
    dispatch(getPatientDetails(id))
    if(cordonnees.length !== 0){
      setFormData(cordonnees);
  }
  },  [dispatch, id]);
  
  const handleClickClose = () => {
    navigate('/dashboard/patients')
    dispatch(reset())
  }
  

  const handleClickSave = (e) => {
    e.preventDefault();
    dispatch(updatePatientDetails(formData))
    setValidation('vos informations ont bien étais enregistrer')

  };
  
  

  const handleChangeSexe = (e) => {
    setFormData({...formData, sexe: e.target.value})
  }
  const handleChangeName = (e) => {
    setFormData({...formData, nom: e.target.value})
  };
  const handleChangeLastName = (e) => {
    setFormData({...formData, prenom: e.target.value})
  };
  const handleChangePaysNaissance = (e) => {
    setFormData({...formData, pays_de_naissance: e.target.value})
  };
  const handleChangeNameNaissance = (e) => {
    setFormData({...formData, nom_de_naissance: e.target.value})
  };
  const handleChangePhone = (e) => {
    setFormData({...formData, phone_number: e.target.value})
  };
  const handleChangeDateNaissance = (e) => {
    setFormData({...formData, date_de_naissance: e.target.value})
  };
  const handleChangeVilleNaissance = (e) => {
    setFormData({...formData, ville_de_naissance: e.target.value})
  };
  const handleChangeEmailNaissance = (e) => {
    setFormData({...formData, email: e.target.value})
  };


  return (
    <Fragment>
        <div className={style.form}>
          <div>
            <p>Sexe</p>
            <div className={style.sexe}>
              <input
              onChange={handleChangeSexe}
              type="radio" id="homme" name="sexe" value="homme" checked={formData.sexe === "homme"} />
              <label htmlFor="homme">Homme</label>
              <input
              onChange={handleChangeSexe}
              type="radio" id="femme" name="sexe" value="femme" checked={formData.sexe === "femme"} />
              <label htmlFor="homme">Femme</label>
            </div>
          </div>
          <div className={style.allinputs}>
            <div className={style.leftinputs}>
                <p>nom</p>
              <div className={style.inputs}>
                <input
                onChange={handleChangeName}
                type="text" placeholder="Nom" value={formData.nom} />
              </div>
                <p>nom de naissance</p>
              <div className={style.inputs}>
                <input
                onChange={handleChangeNameNaissance}
                type="text" placeholder="Nom de naissance" value={formData.nom_de_naissance} />
              </div>
              <p>pays de naissance</p>
              <div className={style.inputs}>
              <select
              onChange={handleChangePaysNaissance}
              name="pays">
                {formData.pays_de_naissance === 'Algérie' ? <option value={formData.pays_de_naissance}>Algérie</option> :
                formData.pays_de_naissance === 'Autre' ? <option value={formData.pays_de_naissance}>Autre</option> : <option></option> }
                <option value="Algérie">Algérie</option>
                <option value="Autre">Autre</option>
              </select>
              </div>
                <p>téléphone portable</p>
              <div className={style.inputs}>
                <input
                onChange={handleChangePhone}
                type="text" placeholder="Téléphone portable" value={formData.phone_number} />
              </div>
            </div>
            <div className={style.rightinputs}>
                  <p>prénom</p>
                <div className={style.inputs}>
                  <input
                  onChange={handleChangeLastName}
                  type="text" placeholder="Prénom" value={formData.prenom} />
                </div>
                  <p>date de naissance</p>
                <div className={style.inputs}>
                  <input
                  onChange={handleChangeDateNaissance}
                  type="text" placeholder="Date de naissance" value={formData.date_de_naissance} />
                </div>
                  <p>ville de naissance</p>
                <div className={style.inputs}>
                  <input
                  onChange={handleChangeVilleNaissance}
                  type="text" placeholder="Ville de naissance" value={formData.ville_de_naissance} />
                </div>
                  <p>émail</p>
                <div className={style.inputs}>
                  <input
                  onChange={handleChangeEmailNaissance}
                  type="text" placeholder="émail" name='email' value={formData.email} />
                </div>
            </div>
          </div>
            <div className={style.succes}>
              <p>{validation}</p>
            </div>
          
              {disabled === false ?

              <div className={style.buttons}>
                  <button
                  onClick={handleClickClose}
                  >Annuler</button>
                  <button
                  onClick={handleClickSave}
                  >Enregistrer la fiche patient</button>
                </div>
                :
                <div className={style.cantModify} >
                  <p>Vous ne pouvez pas modifier les informations de ce profile</p>
                </div>
              }

        </div>
    </Fragment>
  )
}
