import React, { useEffect } from 'react'

import { Autocomplete, Box, Button, FormControlLabel, IconButton, Modal, Switch, TextField } from '@mui/material'

import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import style from './UpdateTimeModal.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getAppoitmentWithThisTimeSlot, getTimeSlot, updateAppoitmentWithThisTimeSlot, updateTimeSlot } from '../../redux/features/Appoitment/AppoitmentSlice';
import { getMinute } from '../Agenda/TableTime/Utils';



const st = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height : 360,
    width : 650,
};


export default function AddTimeModal({ modal, setModal, modifiedItems, setModifiedItems }) {


    const timeSlot = getMinute()

    const dispatch = useDispatch()

    const { appoitwiththis } = useSelector((state) => state.appoitment);

    useEffect(() => {
        dispatch(getAppoitmentWithThisTimeSlot(modifiedItems))
    }, [dispatch])




    const handleClose = () => {
        setModal(!modal)
    }

    const handleChangeStartTime = (event ,value) => {
        setModifiedItems({...modifiedItems, from_time : value})
    }


    const handleChangeEndTime = (event ,value) => {
        setModifiedItems({...modifiedItems, to_time : value})
    }


    const handleChangeAvailable = (event ,value) => {
        setModifiedItems({...modifiedItems, available : value})
    }




    const updatedAppointments = appoitwiththis.map(appointment => ({
        ...appointment,
        from_time: modifiedItems.from_time,
        to_time: modifiedItems.to_time
    }));

    const handleClickSave = () => {
        dispatch(updateTimeSlot(modifiedItems)).then((value) => {
            if (value.payload.message === "succes modified") {
                dispatch(updateAppoitmentWithThisTimeSlot(updatedAppointments)).then((message) => {
                    if (message.payload.message === "Rendez-vous mis à jour avec succès.") {
                        dispatch(getTimeSlot())
                        setModal(false)
                    }
                })
            }
        })
    }







  return (
    <div>
        <Modal
            open={modal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={st}>
            <div className={style.container}>
                <div className={style.text}>
                    <p>Si vous souhaitez modifier le créneau horaire, veuillez noter que les rendez-vous à venir seront également mis à jour en conséquence.</p>
                    <p>Êtes-vous sûr de vouloir mettre à jour les {appoitwiththis.length} prochains rendez-vous ?</p>
                </div>
                <div className={style.form}>
                    <Autocomplete
                        freeSolo
                        onChange={(event ,value) => handleChangeStartTime(event ,value)}
                        disablePortal
                        id="combo-box-demo"
                        value={modifiedItems.from_time}
                        options={timeSlot}
                        sx={{ width: 150 }}
                        style={{ marginBottom : '22px' }}
                        renderInput={(params) => <TextField {...params} label="De" />}
                        />
                    <IconButton style={{ marginLeft : "15px", marginRight : "15px"}} aria-label="tiret">
                        <HorizontalRuleIcon />
                    </IconButton>
                    <Autocomplete
                        freeSolo
                        onChange={(event ,value) => handleChangeEndTime(event ,value)}
                        disablePortal
                        id="combo-box-demo"
                        value={modifiedItems.to_time}
                        options={timeSlot}
                        sx={{ width: 150 }}
                        style={{ marginBottom : '22px' }}
                        renderInput={(params) => <TextField {...params} label="A" />}
                        />
                    <FormControlLabel
                        style={{ marginBottom: "15px", background: "#fff" }}
                        onChange={(event ,value) => handleChangeAvailable(event ,value)}
                        value="start"
                        control={<Switch
                            checked={modifiedItems.available}
                            color="primary"
                        />}
                        label={<span style={{ fontSize: '16px', marginLeft : "15px" }}>En ligne</span>}
                        labelPlacement="top"
                    />
                </div>
                <div className={style.buttons}>
                    <Button
                    onClick={() => handleClickSave()}
                    variant='contained'
                    >Enregistrer les changements</Button>
                </div>
            </div>
        </Box>
        </Modal>
    </div>
  )
}
