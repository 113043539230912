import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";


export default function Layout(props) {




    return (
    <>
        <div>
            {props.children}
        </div>
    </>
    )
}
