import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";





// cdoctor share report with patient
export const ShareReportWithPatient = createAsyncThunk('report/ShareReportWithPatient',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/report/sharereport/", {
        method: 'POST',
        body: JSON.stringify(dataa),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



// create compte rendu
export const createReport = createAsyncThunk('report/createReport',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/report/compterenducreate/", {
        method: 'POST',
        body: JSON.stringify(dataa),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



// get detais de compte rendu
export const getReportDetails = createAsyncThunk('report/getReportDetails',
  async (patient_pk, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/report/compterendudetails/${patient_pk}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



// get list connsultation sur linterface de creation de compte rendu
export const getConsultationOfcompte = createAsyncThunk('report/getConsultationOfcompte',
  async (patient_pk, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/consulatation/listcompteconsultation/${patient_pk}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)




export const getAllReport = createAsyncThunk('report/getAllReport',
  async (patient_pk, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/prescription/allprescription/${patient_pk}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)






// historique 

export const getAllAppoit = createAsyncThunk('report/getAllAppoit',
  async (patient_pk, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/appoitmentalllist/${patient_pk}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)


export const getReportForConsultation = createAsyncThunk('report/getReportForConsultation',
  async (pcdata, thunkAPI) => {
    const { patient_pk, consultation } = pcdata
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/prescription/prescriptconsultationlist/${patient_pk}/${consultation}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)




const ReportSlice = createSlice({
    name: 'report',
    initialState: {
      report: [],
      appoitconsultation : [],
      prescriptionforconsultation : [],
      consultationcompte : [],
      createdrep : "",
      isLoading: false,
      error: null,
    },
    reducers: {},
    extraReducers: {
    // doctor share report with patient
    [ShareReportWithPatient.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [ShareReportWithPatient.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.createdrep = action.payload;
    },
    [ShareReportWithPatient.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    // get list connsultation sur linterface de creation de compte rendu
    [createReport.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [createReport.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.createdrep = action.payload;
    },
    [createReport.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    // get details de compte rendu
    [getReportDetails.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [getReportDetails.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.createdrep = action.payload;
    },
    [getReportDetails.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    // get list connsultation sur linterface de creation de compte rendu
    [getConsultationOfcompte.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [getConsultationOfcompte.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.consultationcompte = action.payload;
    },
    [getConsultationOfcompte.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    // get all report 
    [getAllReport.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [getAllReport.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.report = action.payload;
    },
    [getAllReport.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    // get all appoit and consultation
    [getAllAppoit.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [getAllAppoit.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.appoitconsultation = action.payload;
    },
    [getAllAppoit.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    // get all appoit and consultation
    [getReportForConsultation.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [getReportForConsultation.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.prescriptionforconsultation = action.payload;
    },
    [getReportForConsultation.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    }
});




export default ReportSlice.reducer;
