import React, { useEffect } from 'react'
import { Fragment } from 'react'

import style from './HistoriqueConsultation.module.css'

import SearchIcon from '@mui/icons-material/Search';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllAppoit } from '../../../redux/features/Report/ReportSlice';

import moment from 'moment'
import 'moment/locale/fr';



export default function HistoriqueConsultation() {


  moment.locale('fr')

  const dispatch = useDispatch()
  const params = useParams()
  const patient_pk = params.patientid

  const { appoitconsultation } = useSelector((state) => state.report);

  useEffect(() => {
    dispatch(getAllAppoit(patient_pk))
  },  [dispatch]);





  return (
    <Fragment>

          <div className={style.title}>
            <h2>Historique du patient</h2>
          </div>
          {/* <div className={style.search_space}>
            <div className={style.search}>
              <SearchIcon />
              <input type="text" placeholder="Recherche" />
            </div>
          </div> */}
          <div className={style.all}>
              {appoitconsultation && appoitconsultation.map((el, i) => 
                <div key={i} className={style.historique_content}>
                  <div className={style.day}>
                    <h2>{el.dateList}</h2>
                  </div>
                  {el.data && el.data.map((item, i) => 
                    <div key={i} className={style.card}>
                      <div className={style.card_content}>
                        <div className={style.card_title}>
                          <DateRangeIcon className={style.daterangeicon} />
                          <h2>{item.type}</h2>
                        </div>
                        <div className={style.card_info}>
                          <h3>{item.motifconsultation}</h3>
                          <p>Docteur {item.doctor}</p>
                          <p>{moment(item.date).format('dddd D MMMM')} a {moment(item.time, "HH:mm").format("LT")}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                )}
          </div>
    </Fragment>
  )
}
