import { Autocomplete, FormControlLabel, IconButton, Switch, TextField } from '@mui/material'
import React, { Fragment, useState } from 'react'


import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';


import style from './AddTimeForm.module.css'
import { getMinute } from '../Agenda/TableTime/Utils';
import { createTimeSlot } from '../../redux/features/Appoitment/AppoitmentSlice';
import { useDispatch } from 'react-redux';



export default function AddTimeForm({ setAddForm }) {


    const timeSlot = getMinute()

    const dispatch = useDispatch()



    const [data, setData] = useState({
        from_time : null,
        to_time : null,
        available : true
    })


    const handleChangeStartTime = (event ,value) => {
        setData({...data, from_time : value})
    }


    const handleChangeEndTime = (event ,value) => {
        setData({...data, to_time : value})
    }


    const handleChangeAvailable = (event ,value) => {
        setData({...data, available : value})
    }



    const handleClickSave = () => {
        dispatch(createTimeSlot(data)).then(() => {
            setAddForm(false)
        })
    }

  return (
    <Fragment>
        <div className={style.ListTimeSlot}>
            <div className={style.ItemsInput}>
                    <Autocomplete
                          freeSolo
                          onChange={(event ,value) => handleChangeStartTime(event ,value)}
                          disablePortal
                          id="combo-box-demo"
                          // value={modifiedItems.from_time}
                          options={timeSlot}
                          sx={{ width: 150 }}
                          style={{ marginBottom : '22px' }}
                          renderInput={(params) => <TextField {...params} label="De" />}
                      />
                      <IconButton style={{ marginLeft : "15px", marginRight : "15px"}} aria-label="tiret">
                        <HorizontalRuleIcon />
                      </IconButton>
                      <Autocomplete
                          freeSolo
                          onChange={(event ,value) => handleChangeEndTime(event ,value)}
                          disablePortal
                          id="combo-box-demo"
                          // value={modifiedItems.from_time}
                          options={timeSlot}
                          sx={{ width: 150 }}
                          style={{ marginBottom : '22px' }}
                          renderInput={(params) => <TextField {...params} label="A" />}
                      />
                      <FormControlLabel
                          style={{ marginBottom: "15px", background: "#fff" }}
                          value="start"
                          control={<Switch
                          onChange={(event ,value) => handleChangeAvailable(event ,value)}
                            color="primary" checked={data.available} />}
                          label={<span style={{ fontSize: '16px', marginLeft : "15px" }}>En ligne</span>}
                          labelPlacement="top"
                      />
                    <IconButton 
                    onClick={handleClickSave}
                    style={{ marginLeft : "15px", marginRight : "15px"}} aria-label="tiret">
                        <DownloadDoneIcon />
                    </IconButton>
            </div>
        </div>
    </Fragment>
  )
}
