import React, { Fragment, useState } from 'react'


import { Link, Navigate, useNavigate } from 'react-router-dom';

import EmailIcon from "@mui/icons-material/Email";
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";

import style from './ChangeForgotPassword.module.css'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { changeForgotPassword } from '../../redux/features/User/authSlice';

export default function ChangeForgotPassword() {

    const [validation, setValidation] = useState("")
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [code, setCode] = useState("");

    const [data, setData] = useState({
        code : "",
        new_password1 : "",
    })


    const handleChangePassword = (elem) => {
        setData({...data, new_password1 : elem.target.value})
      }

    
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    
    
    const handleSubmit = () => {
        dispatch(changeForgotPassword(data)).then((value) => {
            if (value.payload.message === "Password succefully changed") {
                navigate('/')
            }
        })
    }
    
    const handleChangeCode = (event) => {
        const { value } = event.target;
        const numericValue = value.replace(/\D/g, ""); // Retirer tous les caractères non numériques
        const truncatedValue = numericValue.slice(0, 6); // Limiter la saisie à 6 chiffres
        setData({...data, code : event.target.value})
        setCode(truncatedValue);
      };

  return (
    <Fragment>
      <div className={style.content}>
          <div className={style.form}>
            <h1>Veuillez saisir le code que vous avez reçu</h1>

            <TextField
                style={{ width : 400, marginBottom : "40px" }}
                className={style.input} 
                type="text"
                value={code}
                onChange={(event) => handleChangeCode(event)}
                label="Veuillez saisir votre code"
                />

            <FormControl
                style={{ width : 400, marginBottom : "40px" }}
                className={style.input} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Entré votre nouveau mot de passe</InputLabel>
                    <OutlinedInput
                      onChange={(elem) => handleChangePassword(elem)}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={data.new_password1}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Entré votre nouveau mot de passe"
                    />
            </FormControl>

            {validation &&
              <div className={style.danger}>
                <p>{validation}</p>
              </div>
            }
            <div className={style.btn}>
              <input
              onClick={handleSubmit}
              type="submit" value="Soumettre"/>
            </div>
          </div>
      </div>
    </Fragment>
  )
}
