import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import Actions from '../Actions/Actions'
import style from './PatientDetail.module.css'
import SideBarDetails from '../SideBarDetails/SideBarDetails'
import PatientOutletHeader from '../PatientOutletHeader/PatientOutletHeader'

export default function PatientDetail() {

  return (
    <Fragment>
      <div className={style.patient_details}>
        <div className={style.patient_sidebar}>
          <SideBarDetails />
        </div>
        <div className={style.working_space}>
            <PatientOutletHeader />
            <Outlet />
        </div>
        <div className={style.patient_actions}>
          <Actions />
        </div>
      </div>
    </Fragment>
  )
}
