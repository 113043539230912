import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { getAllPatients, modalFunc } from '../../redux/features/Patient/patientSlice';
import PatientCreatModal from './PatientCreatModal/PatientCreatModal';
import PatienTable from './PatienTable/PatienTable';

import style from './Patients.module.css'
import AddIcon from '@mui/icons-material/Add';



export default function Patients() {

  const { modal } = useSelector((state) => state.patients)

  const dispatch = useDispatch()

  const handleClickModal = () => {
    dispatch(modalFunc())
    dispatch(getAllPatients())
  }


  return (
    <>
    <div className={style.main}>
      <div className={style.title}>
        <h1>Liste Des Patients</h1>
      </div>
      <div className={style.widgets}>
        <div
        onClick={handleClickModal}
        className={style.add_button}>
          <AddIcon className={style.add_icon} style={{fontSize: "24px"}}/>
          <span>Ajouter</span>
        </div>
      </div>


      <div className="table">
        <PatienTable />
      </div>
    </div>
    {modal &&
      <PatientCreatModal />
    }
    </>
  )
}
