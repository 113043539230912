import React, { useState, Fragment, useRef } from 'react'
import ToolBar from './ToolBar/ToolBar';
import OrdonanceMedicament from './OrdonanceMedicament/OrdonanceMedicament';
import OrdonanceBiologie from './OrdonanceBiologie/OrdonanceBiologie';
import OrdonanceImagerie from './OrdonanceImagerie/OrdonanceImagerie';
import OrdonanceParaMedicaux from './OrdonanceParaMedicaux/OrdonanceParaMedicaux'
import style from './PlanConsultation.module.css'
import OrdonanceDetail from './OrdonanceDetail/OrdonanceDetail';
import { useSelector } from 'react-redux';

import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';




export default function PlanConsultation() {

  const [page, setPage] = useState(0)
  const componentRef = useRef();
  


  const PlanTitles = ["Plan de soins", "Ordonnance de médicaments", "Ordonnance de biologie", "Ordonnance d’imagerie", "Soins paramedicaux", 'Ordonnance', "partager l'ordonance"];



  const PageDisplay = () => {
    if (page === 0) {
      return <ToolBar setPage={setPage} />
    } else if (page === 1) {
      return <OrdonanceMedicament setPage={setPage} />
    } else if (page === 2) {
      return <OrdonanceBiologie setPage={setPage} />
    } else if (page === 3) {
      return <OrdonanceImagerie setPage={setPage} />
    } else if (page === 4) {
      return <OrdonanceParaMedicaux setPage={setPage} />
    } else if (page === 5) {
      return <OrdonanceDetail componentRef={componentRef} setPage={setPage} />
    }
  }

  return (
    <Fragment>
        <div className={style.title}>
          <h2>{PlanTitles[page]}</h2>
        {page === 5 && 
          <div>
              <ReactToPrint
                  trigger={() => 
                      <div className={style.buttonPrint}>
                          <PrintIcon />
                          <span>Imprimer</span>
                      </div>
                  }
                  content={() => componentRef.current}
              />
          </div>
        }
        </div>
        {PageDisplay()}
    </Fragment>
  )
}
