import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

import style from "./Agenda.module.css";
import Calendar from "./Calendar/Calendar";


import TableTime from "./TableTime/TableTime";
import { getWeek } from "./TableTime/Utils";

export default function Agenda() {

  const [startDate, setStartDate] = useState(moment())
  const endDate = moment(startDate).add(6, 'day')
  const week = getWeek(startDate, endDate)

  return (
    <>
      <div className={style.agendaContainer}>
        <div className={style.calendarContainer}>
          <Calendar
            startDate={startDate}
            setStartDate={setStartDate}
          />
        </div>
        <div className={style.TabelTimeContainer}>
          <TableTime
            week={week}
          />
        </div>
      </div>
    </>
  );
}