import React, { Fragment } from 'react'
import style from './Naissance.module.css'


export default function Naissance({ formData, setFormData }) {

  const handleSubmit = e => e.preventDefault();


  const handleNomNaissance = (e) => {
    setFormData({...formData, nom_de_naissance: e.target.value});
  }

  const handlePayNaissance = (e) => {
    setFormData({...formData, pays_de_naissance: e.target.value});
  }
  const handleDateNaissance = (e) => {
    setFormData({...formData, date_de_naissance: e.target.value});
  }
  const handleVilleNaissance = (e) => {
    setFormData({...formData, ville_de_naissance: e.target.value});
  }

  return (
    <Fragment>
      <div className={style.form}>
        <form
        onSubmit={handleSubmit}
        >
          <div className={style.inputs}>
            <div className={style.leftform}>
              <p>Nom de naissance</p>
              <input
              value={formData.nom_de_naissance}
              onChange={handleNomNaissance}
              type="text" placeholder="Nom de naissance"/>
              <p>Pays de naissance</p>
              <select
              onChange={handlePayNaissance}
              name="pays" id="">
                <option></option>
                <option value="Algérie">Algérie</option>
                <option value="Autre">Autre</option>
              </select>
            </div>
            <div className={style.rightform}>
              <p>Date de naissance</p>
              <input
              value={formData.date_de_naissance}
              onChange={handleDateNaissance}
              type="date" />
              <p>Ville de naissance</p>
              <input
              value={formData.ville_de_naissance}
              onChange={handleVilleNaissance}
              type="text" placeholder="Ville de naissance"/>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  )
}
