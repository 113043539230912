import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux';

import img1 from '../../../assets/images/avatar.jpg'
import style from './PatientCard.module.css'


import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPatientDetails } from '../../../redux/features/Patient/patientSlice';

import PersonIcon from '@mui/icons-material/Person';


export default function PatientCard() {

  const { cordonnees } = useSelector((state) => state.patients);
  const datedenaissance = cordonnees.date_de_naissance


  const dispatch = useDispatch()
  const params = useParams()
  const id = params.patientid

  
  useEffect(() => {
    dispatch(getPatientDetails(id))
  },  [dispatch]);

  const getAge = () => {
    if (cordonnees.length !== 0) {
      const birthyear = datedenaissance.split('-')[0]
      const today = new Date();
      const age = today.getFullYear() - birthyear
      return age
    }
  }





  return (
    <Fragment>
        <div className={style.patient_card}>
            <div className={style.patient_info}>
                <h2>M. {cordonnees.nom + ' ' + cordonnees.prenom}</h2>
                <h3>{datedenaissance} ({getAge()})</h3>
            </div>
            <div className={style.avatar}>
                <PersonIcon />
                {/* <img src={img1} alt="patient avatar" /> */}
            </div>
        </div>
      
    </Fragment>
  )
}
