import React, { useEffect, useRef } from 'react'
import { Fragment } from 'react'


import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';
import style from './CompteRenduDetails.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReportDetails } from '../../redux/features/Report/ReportSlice';

import moment from 'moment';

import 'moment/locale/fr';


import ReactToPrint from 'react-to-print';

export default function CompteRenduDetails() {

  moment.locale('fr')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()
  const { pathname } = location;
  const componentRef = useRef();

  const patient = params.patientid
  const compterendu = params.compterenduid
  const consultation = params.consultationid

  const { createdrep } = useSelector((state) => state.report)

  
  useEffect(() => {
    dispatch(getReportDetails(compterendu))
  },  [dispatch]);




  const handleReturn = () => {
    if (pathname === `/dashboard/patients/${patient}/documents/compterendu/${compterendu}/`) {
      navigate(`/dashboard/patients/${patient}/documents/`)
    } else if (pathname === `/dashboard/patients/${patient}/historique/consultation/${consultation}/compterendu/${compterendu}/`) {
      navigate(`/dashboard/patients/${patient}/historique/consultation/${consultation}/`)
    }
  }


  return (
    <Fragment>
        <div className={style.container}>
          <div className={style.header}>
            <div className={style.buttons}>
              <div
              onClick={handleReturn}
              className={style.button}>
                <KeyboardReturnIcon />
                <button>retour</button>
              </div>
                <div>
                  <ReactToPrint
                      trigger={() => 
                        <div className={style.button}>
                          <PrintIcon />
                          <button>imprimer</button>
                      </div>
                      }
                      content={() => componentRef.current}
                  />
                </div>
            </div>
          </div>


          {createdrep !== "" &&
            <div ref={componentRef} className={style.content}>
              <div className={style.infos}>
                <div className="doctor">
                  <h2>Dr {createdrep.created_by.nom && createdrep.created_by.nom + " " + createdrep.created_by.prenom }  </h2>
                  <p>{createdrep.created_by.specilite.nom && createdrep.created_by.specilite.nom}</p>
                  <p>{createdrep.created_by.adresse && createdrep.created_by.adresse }, {createdrep.created_by.location.id && createdrep.created_by.location.ville + ' ' + createdrep.created_by.location.code}</p>
                </div>

                <div className="patient">
                  <p>{createdrep.date && moment(createdrep.date).format("dddd, Do MMMM YYYY") }</p>
                  {/* <p>{Le 22 juin 2022}</p> */}
                  <h2>{createdrep.patient.nom && createdrep.patient.nom + " "} {createdrep.patient.prenom && createdrep.patient.prenom} </h2>
                  <p>Né le {createdrep.patient.date_de_naissance && createdrep.patient.date_de_naissance}</p>
                </div>
              </div>
              <div className={style.report}>
                <div className={style.item}>
                  <h3>Antecedant</h3>
                  <p>{createdrep.antecedant && createdrep.antecedant}</p>
                </div>
                <div className={style.item}>
                  <h3>Rapport médecin</h3>
                  <p>{createdrep.text && createdrep.text} </p>
                </div>
                <div className={style.item}>
                  <h3>Remarque</h3>
                  <p>{createdrep.remarque && createdrep.remarque}</p>
                </div>
              </div>
            </div>
          }
        </div>
    </Fragment>
  )
}
