import React, { Fragment, useEffect } from 'react'
import style from './Documents.module.css'


import moment from 'moment';
import 'moment/locale/fr';


import PatientOutletHeader from '../PatientOutletHeader/PatientOutletHeader'

import MedicationIcon from '@mui/icons-material/Medication';
import ShareIcon from '@mui/icons-material/Share';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import SummarizeIcon from '@mui/icons-material/Summarize';


import { useDispatch, useSelector } from 'react-redux';

import { getAllReport } from '../../../redux/features/Report/ReportSlice'
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { getPrescriptionDetails } from '../../../redux/features/Prescription/PrescriptionSlice';
import ShareModal from '../ShareModal/ShareModal';
import { useState } from 'react';



export default function Documents() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const patient_pk = params.patientid

  const { report } = useSelector((state) => state.report);

  const [modal, setModal] = useState(false)
  const [ordo, setOrdo] = useState({})


  useEffect(() => {
    dispatch(getAllReport(patient_pk))
  },  [dispatch]);


  const handleClickOpen = (item) => {
    if(item.type === "ordonance") {
      navigate(`/dashboard/patients/${patient_pk}/documents/ordononce/${item.id}/`)
    } else if (item.type === "ordonance (p)") {
      navigate(`/dashboard/patients/${patient_pk}/documents/ordononce/${item.id}/`)
    } else if(item.type === "compte rendu") {
      navigate(`/dashboard/patients/${patient_pk}/documents/compterendu/${item.id}/`)
    } else if (item.type === "compte rendu (p)") {
      navigate(`/dashboard/patients/${patient_pk}/documents/compterendu/${item.id}/`)
    }
  }


  const handleClickDocument = (item) => {
    setModal(true)
    setOrdo(item)
  }


  return (
    <Fragment>
          {report && report.map((el, i) => 
              <div key={i} >
                <div className={style.date}>
                  <span>{moment(el.dateList).format('DD MMMM YYYY')}</span>
                </div>
                {el.data && el.data.map((item, i) => 
                  <div key={i} className={style.content}>
                    <div className={style.item}>
                      <div
                      onClick={() => handleClickOpen(item)}
                      className={style.it}>
                        {item.type === "ordonance" ? 
                          <MedicationIcon />
                          :
                          <SummarizeIcon />
                        }
                        <p>{item.type}</p>
                      </div>
                      <div className={style.it}>
                        <p>{item.date}</p>
                      </div>
                      <div className={style.it}>
                        <p>Dr {item.doctor}</p>
                      </div>
                      {/* <div
                      onClick={() => handleClickOpen(item)}
                      className={style.it}>
                        <OpenInBrowserIcon />
                        <p>OUVRIR</p>
                      </div> */}
                      <div
                      onClick={() => handleClickDocument(item)}
                      className={style.it}>
                        <ShareIcon />
                        <p>PARTAGER</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
          )}
          {modal &&
            <ShareModal ordo={ordo} setModal={setModal} />
          }
    </Fragment>
  )
}
