import React, { useEffect } from 'react'
import { Fragment } from 'react-is'
import PatientOutletHeader from '../PatientOutletHeader/PatientOutletHeader'


import style from './Historique.module.css'

import moment from 'moment'
import 'moment/locale/fr';

import EventIcon from '@mui/icons-material/Event';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllAppoit } from '../../../redux/features/Report/ReportSlice';


export default function Historique() {

  moment.locale('fr')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const patient_pk = params.patientid

  const { appoitconsultation } = useSelector((state) => state.report);

  useEffect(() => {
    dispatch(getAllAppoit(patient_pk))
  },  [dispatch, patient_pk]);


  const handleClickItem = (item) => {
    if (item.type === "Consultation") {
      navigate(`/dashboard/patients/${patient_pk}/historique/consultation/${item.id}/`)
    }
  }


  return (
    <Fragment>
          {appoitconsultation && appoitconsultation.map((el, i) => 
              <div key={i}>
                <div className={style.date}>
                  <span>{el.dateList}</span>
                </div>
                  {el.data && el.data.map((item, i) => 
                    <div key={i} className={style.content}>
                      <div className={style.item}>
                        <div
                        onClick={() => handleClickItem(item)}
                        className={style.it}>
                          <EventIcon />
                          <p>{item.type}</p>
                        </div>
                        <div className={style.it}>
                          <p>{item.motifconsultation}</p>
                        </div>
                        <div className={style.it}>
                          <p>Docteur {item.doctor}</p>
                        </div>
                        <div className={style.it}>
                          <p>{moment(item.date).format('dddd D MMMM')} a {moment(item.time, "HH:mm").format("LT")}</p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
          )}
    </Fragment>
  )
}
