import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const createConsultation = createAsyncThunk('consultation/createConsultation',
  async (consData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/consulatation/startconsulatation/", {
        method: 'POST',
        body: JSON.stringify(consData),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const getFiltredConsultation = createAsyncThunk('consultation/getFiltredConsultation',
async (id, thunkAPI) => {
  const {rejectWithValue} = thunkAPI;
  try {
    const res = await fetch(`https://api.doctodz.com/api/consulatation/listfiltredconsultation/${id}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `token ${localStorage.getItem('token')}`,
        "Accept": "application/json",
      },
    });
    const data = await res.json();
    return data
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
)



export const updateConsultation = createAsyncThunk('consultation/updateConsultation',
async (observation, thunkAPI) => {
  const {rejectWithValue} = thunkAPI;
  const { consultation } = observation
  try {
    const res = await fetch(`https://api.doctodz.com/api/consulatation/updateconsultation/${consultation}/`, {
      method: 'PUT',
      body: JSON.stringify(observation),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `token ${localStorage.getItem('token')}`,
        "Accept": "application/json",
      },
    });
    const data = await res.json();
    return data
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
)

export const getMotifConsultation = createAsyncThunk('consultation/getMotifConsultation',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/consulatation/listmotifconsultationfordoctor/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }

);


const ConsultationSlice = createSlice({
    name: 'consultation',
    initialState: {
        consultation: [],
        filtredconsultation: [],
        updatedconsultation : {} ,
        moticonsultation: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
      // create consultation  
        [createConsultation.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [createConsultation.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.consultation = action.payload;
        },
        [createConsultation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
      // getFiltredConsultation 
        [getFiltredConsultation.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getFiltredConsultation.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.filtredconsultation = action.payload;
        },
        [getFiltredConsultation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
      // update consultation 
        [updateConsultation.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [updateConsultation.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.updatedconsultation = action.payload;
        },
        [updateConsultation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
      // update consultation 
        [getMotifConsultation.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getMotifConsultation.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.moticonsultation = action.payload;
        },
        [getMotifConsultation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});




export default ConsultationSlice.reducer;