import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// https://api.doctodz.com/
export const planifyAppoitment = createAsyncThunk('appoitment/planifyAppoitmentt',
  async (formData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/appointments/appoitmentplanify/", {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const getAppoitment = createAsyncThunk('appoitment/getAppoitment',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/appointments/appoitmentlist/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const getClosedTimeSlot = createAsyncThunk('appoitment/getClosedTimeSlot',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/appointments/closedtimelist/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



export const getTimeSlot = createAsyncThunk('appoitment/getTimeSlot',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/appointments/timeslotlist/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const createCloseTimeSlot = createAsyncThunk('appoitment/createCloseTimeSlot',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/appointments/closetimeslotcreate/", {
        method: 'POST',
        body: JSON.stringify(dataa),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)

export const deleteCloseTimeSlot = createAsyncThunk('appoitment/deleteCloseTimeSlot',
  async (id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/closetimeslotdelete/${id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const updateTimeSlot = createAsyncThunk('appoitment/updateTimeSlot',
  async (modifiedItems, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { id } = modifiedItems
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/timeslotupdate/${id}/`, {
        method: 'PUT',
        body: JSON.stringify(modifiedItems),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const updateAppoitmentWithThisTimeSlot = createAsyncThunk('appoitment/updateAppoitmentWithThisTimeSlot',
  async (updatedAppointments, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/appointments/AppointmentInThisUpdateView/", {
        method: 'PUT',
        body: JSON.stringify(updatedAppointments),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



export const getAppoitmentWithThisTimeSlot = createAsyncThunk('appoitment/getAppoitmentWithThisTimeSlot',
  async (modifiedItems, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { from_time, to_time, created_by } = modifiedItems
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/appoitmentlistbyslot/${from_time}/${to_time}/${created_by}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const createTimeSlot = createAsyncThunk('appoitment/createTimeSlot',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/appointments/timeslotcreate/", {
        method: 'POST',
        body: JSON.stringify(dataa),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)




const AppoitmentSlice = createSlice({
    name: 'appoitment',
    initialState: {
        appointments: [],
        slots: [],
        newappoit : {},
        timeslot: [],
        appoitwiththis : [],
        isLoading: false,
        error: null,
    },
    reducers: {
    },
    extraReducers: {
      // create appoitment  
        [planifyAppoitment.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [planifyAppoitment.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.newappoit = action.payload;
        },
        [planifyAppoitment.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
      // get appitment   
        [getAppoitment.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getAppoitment.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.appointments = action.payload;
        },
        [getAppoitment.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get Closed TimeSlot  
        [getClosedTimeSlot.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getClosedTimeSlot.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.slots = action.payload;
        },
        [getClosedTimeSlot.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get TimeSlot  
        [getTimeSlot.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getTimeSlot.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.timeslot = action.payload;
        },
        [getTimeSlot.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get TimeSlot  
        [getAppoitmentWithThisTimeSlot.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getAppoitmentWithThisTimeSlot.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.appoitwiththis = action.payload;
        },
        [getAppoitmentWithThisTimeSlot.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // add timeslot
        [createTimeSlot.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [createTimeSlot.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.timeslot.push(action.payload);
        },
        [createTimeSlot.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});


// export const { updateTimeslot } = AppoitmentSlice.actions;

export default AppoitmentSlice.reducer;