import React, { Fragment } from 'react'
import CivilityForm from './CivilityForm/CivilityForm'
import Naissance from './Naissance/Naissance'
import Cordonne from './Cordonne/Cordonne'
import style from './MultiForm.module.css'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux'

import { modalFunc, createProfile, addPAtientToList, getPatients } from "../../redux/features/Patient/patientSlice";
import { useState } from 'react'
import SearchPatient from './SearchPatient/SearchPatient'



export default function MultiForm() {

  const { allpatients, patients } = useSelector((state) => state.patients)


  const [page, setPage] = useState(0)
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    sexe: "",
    nom_de_naissance: "",
    date_de_naissance: "2000-01-01",
    pays_de_naissance: "",
    ville_de_naissance: "",
    email: "",
    phone_number: "",
  })

  const dispatch = useDispatch();

  const handleClickModal = () => {
    dispatch(modalFunc());
  };
  
  const handleClickInsert = () => {
    dispatch(createProfile(formData)).unwrap().then((Response) => {
      if (Response.message === "succefuly created") {
        const userData = {
          'id' : Response.data.id
        }
        dispatch(addPAtientToList(userData)).then(() => {
          dispatch(getPatients())
        });
        dispatch(modalFunc());
      }
    })
    .catch((rejectedValueOrSerializedError) => {
      console.log(rejectedValueOrSerializedError);
    });
  };

  const FormTitles = ["Civilité", "Naissance", "Contact"];


  const PageDisplay = () => {
    if (page === 0) {
      return <SearchPatient />
    } else if (page === 1) {
      return <CivilityForm formData={formData} setFormData={setFormData} />
    } else if (page === 2) {
      return <Naissance formData={formData} setFormData={setFormData} />
    } else if (page === 3) {
      return <Cordonne formData={formData} setFormData={setFormData} />

    }
  }

  const [text, setText] = useState('')
  const [suggestion, setSuggestion] = useState([])


  const onChangeHandler = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = allpatients.filter(patient => {
        const regex = new RegExp(`${text}`, "gi");
        const allData = patient.nom.concat(patient.prenom)
        const allData2 = allData.concat(patient.email)
        return allData2.match(regex)
      })
    }
    setSuggestion(matches)
    setText(text)
  }

  const onSuggestHandler = (suggestion) => {
    const userData = {
      'id' : suggestion.id
    }
    dispatch(addPAtientToList(userData)).then(() => {
      dispatch(getPatients())
    });
    dispatch(modalFunc());
  }


  return (
    <Fragment>
          <div className={style.close}>
            <CloseIcon style={{fontSize: "30px"}}
            onClick={handleClickModal}
            />
          </div>

        {page === 0 ?
              <div className={style.container}>
                  <div className={style.addbutton}>
                      <button
                      onClick={() => setPage(1)}
                      >
                          Ajouté un nouveau patient
                      </button>
                  </div>


                  <div className={style.search}>
                    <div>
                      <input
                        onChange={e => onChangeHandler(e.target.value)}
                        value={text}
                        type="text" placeholder="Nom ou email de votre patient" />
                    </div>
                    <div className={style.allsuggestion}>
                      {suggestion && suggestion.map((suggestion, i) =>
                      <div key={i}
                          onClick={() => onSuggestHandler(suggestion)}
                          className={style.suggestion}>
                          {suggestion.nom} {suggestion.prenom} <span>{suggestion.email}</span>
                      </div>
                      )}
                    </div>
                  </div>

              </div>
        :
          <div className={style.multiform}>
          
              <div className={style.title}>
                <h1>{FormTitles[page]}</h1>
              </div>

            <div className={style.progressbar}>
              <div className={style.progress} style={{ width: page === 1 ? '33.3%' : page === 2 ? '66.6%' : "100%" }}></div>
            </div>
            <div className={style.form_container}>
              {PageDisplay()}
              <div className={style.buttons}>
                  <button
                  onClick={() => {
                    setPage((currPage) => currPage - 1);
                  }}
                  className={page === 0 ? style.hide : ""}
                  >Précédent</button>
                  
                  {page === 3 ? 
                  <button
                  onClick={handleClickInsert}
                  >Terminé</button>
                  :
                  <button
                  onClick={() => {
                    setPage((currPage) => currPage + 1);
                  }}
                  >Valider</button>
                  }
                </div>
            </div>
          </div>

        }
    </Fragment>
  )
}
