import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAppoitment, getClosedTimeSlot, getTimeSlot } from '../../../redux/features/Appoitment/AppoitmentSlice'




import style from './TableTime.module.css'
import { getHours } from './Utils'

import AgendaModal from '../AgendaModal/AgendaModal'
import { getPatients } from '../../../redux/features/Patient/patientSlice'
import { getMotifConsultation } from '../../../redux/features/Consultation/ConsultationSlice'

import { lazy, Suspense } from 'react';
import { CircularProgress } from '@mui/material'

const Day = lazy(() => import('../Day/Day'));




export default function TableTime({ week }) {

  const [modal, setModal] = useState(false)
  const [dateTime, setDateTime] = useState({
    from_time : '',
    to_time : '',
    date : '',
    timeid : '',
  })
  const hours = getHours()



  const dispatch = useDispatch()


  const { appointments, slots } = useSelector((state) => state.appoitment);

    useEffect(() => {
        dispatch(getAppoitment())
        dispatch(getClosedTimeSlot())
        dispatch(getPatients())
        dispatch(getMotifConsultation())
        dispatch(getTimeSlot())
    }, [dispatch])




  return (
    <Fragment>
      <div className={style.header}>
          <div className={style.days}>
            <div>
            <div className={style.gmt}>
                <span>
                
                </span>
              </div>
              <div className={style.hourBarL}>
                {hours.map((el, i) => (
                  <div key={i} className={style.hour}>
                    <span>{moment(el, "HH:mm").format('LT')}</span>
                  </div>
                ))}
              </div>
            </div>

            {week.map((day ,i) => (
              <Suspense fallback={<CircularProgress />} key={i} >
                <Day
                  dateTime={dateTime}
                  setDateTime={setDateTime}
                  appointments={appointments}
                  slots={slots}
                  setModal={setModal}
                day={day} />
              </Suspense>
            ))}

            <div>
              <div className={style.gmt}>
                <span>
                  GMT+1
                </span>
              </div>
              <div className={style.hourBarR}>
                {hours.map((el, i) => (
                <div key={i} className={style.hour}>
                  <span >{moment(el, "HH:mm").format('LT')}</span>
                </div>
                ))}
              </div>
            </div>
          </div>
      </div>


      {modal &&
        <AgendaModal 
          dateTime={dateTime}
          setModal={setModal} />
      }
    </Fragment>
  )
}
