import moment from 'moment'

import 'moment/locale/fr';



import { useEffect } from "react";




export const getMinute = (minutes = ["00", "30"], seconds = ":00") => {
  let times = [];
  for (var i = 0; i < 24; i++) {
    for (var j = 0; j < minutes.length; j++) {
      var time = i + ":" + minutes[j];
      if (i < 10) {
        time = "0" + time;
      }
      times.push(time + seconds);
    }
  }
  const move = times.splice(0, 8 * minutes.length);
  times = [...times, ...move];
  return times;
};


export const getWeek = (startDate, endDate) => {
    moment.locale('fr')
    let now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
    }
    return dates;
};

export const getHours = (minutes = ["00", "30"], seconds = ":00") => {
  let times = [];
  for (var i = 0; i < 24; i++) {
    for (var j = 0; j < minutes.length; j++) {
      var time = i + ":" + minutes[j];
      if (i < 10) {
        time = "0" + time;
      }
      times.push(time + seconds);
    }
  }
  const move = times.splice(0, 8 * minutes.length);
  times = [...times, ...move];
  return times;
};





export default function useOutSideClick(ref, handler) {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handler();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref };
}



// export const getHours = (minutes = ["00", "30"], seconds = ":00") => {
//   let times = [];
//   for (var i = 0; i < 24; i++) {
//     for (var j = 0; j < minutes.length; j++) {
//       var time = i + ":" + minutes[j];
//       if (i < 10) {
//         time = "0" + time;
//       }
//       times.push(time + seconds);
//     }
//   }
//   times = [...times];
//   return times;
// };



// export const getMinute = () => {
//   const items = [];
//   new Array(24).fill().forEach((acc, index) => {
//       items.push(moment( {hour: index} ).format('HH:mm:ss'));
//       items.push(moment({ hour: index, minute: 30 }).format("HH:mm:ss"));
//       // items.push(moment({ hour: index, minute: 30 }).format("dddd, MMMM Do YYYY, HH:mm"));
//   })
//   return items;
// }


// export const getWeek = (startDay) => {
//     moment.locale('fr')
//     const res = Array(7).fill().map(() => 
//         startDay.add(1, 'd').format('YYYY-MM-DD')
//     );
//     return res;
// }