import React from 'react'
import { Fragment } from 'react'
import SideBarParameter from './SideBarParameter/SideBarParameter'

import style from './Parametre.module.css'
import { Outlet, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';




export default function Parametre() {



  return (
    <Fragment>
      <div className={style.main_parameter}>
        <div className="sidebarparameter">
          <SideBarParameter />
        </div>


        <div className={style.workspace}>
          <Outlet />
        </div>
      </div>
      
    </Fragment>
  )
}
