import React, { Fragment } from 'react'
import { reset } from '../../../redux/features/Patient/patientSlice'


import style from './PatientOutletHeader.module.css'


import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function PatientOutletHeader() {


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const handleClickClose = () => {
        navigate('/dashboard/patients')
        dispatch(reset())
    }

  return (
    <Fragment>
        <div className={style.header}>
            <div className={style.info_pages}>
                <p>Dossier patient</p>
                <ChevronRightIcon className={style.icons} style={{fontSize: "25px"}} />
                <p><span>{splitLocation[4]}</span></p>
            </div>
            <div
            onClick={handleClickClose}
            className={style.close_btn}>
                <p>Fermé le dossier patient</p>
                <CloseIcon className={style.icons} />
            </div>
        </div>
    </Fragment>
  )
}
