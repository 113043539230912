import React, { Fragment } from 'react'
import PatientOutletHeader from '../PatientOutletHeader/PatientOutletHeader'


import style from './Taches.module.css'



export default function Taches() {
  return (
    <Fragment>
      <div className={style.cordonnees} >
        <div>
          <PatientOutletHeader />
        </div>
      </div>
    </Fragment>
  )
}
