import React, { useEffect, useRef } from 'react'
import { Fragment } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


import moment from 'moment';
import 'moment/locale/fr';

import style from './OrodonanceDetails.module.css'

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';


import ReactToPrint from 'react-to-print';
import { getPrescriptionDetails } from '../../redux/features/Prescription/PrescriptionSlice';



export default function OrodonanceDetails() {


    const componentRef = useRef();
    const dispatch = useDispatch()

    

    moment.locale('fr')
    const navigate = useNavigate()
    const params = useParams()
    const patient_pk = params.patientid
    const ordonance = params.ordonanceid
    const consultation = params.consultationid
    const location = useLocation()


    const { prescriptiondetails } = useSelector((state) => state.prescription)

    useEffect(() => {
        dispatch(getPrescriptionDetails(ordonance))
      },  [dispatch]);

    const handleClickReturn = () => {
        if (location.pathname === `/dashboard/patients/${patient_pk}/documents/ordononce/${ordonance}/`) {
            navigate(`/dashboard/patients/${patient_pk}/documents/`)
        }
        else if (location.pathname === `/dashboard/patients/${patient_pk}/historique/consultation/${consultation}/ordonance/${ordonance}/`) {
            navigate(`/dashboard/patients/${patient_pk}/historique/consultation/${consultation}/`)
        }
    }





    return (
        <Fragment>
            <div className={style.container}>
                <div className={style.buttons}>
                        <div
                        onClick={handleClickReturn}
                        className={style.button}>
                            <KeyboardReturnIcon />
                            <span>Retour</span>
                        </div>
                        <div>
                            <ReactToPrint
                                trigger={() => 
                                    <div className={style.buttonPrint}>
                                        <PrintIcon />
                                        <span>Imprimer</span>
                                    </div>
                                }
                                content={() => componentRef.current}
                            />
                        </div>
                </div>
                <div ref={componentRef} className={style.body}>
                    <div className={style.bodyheader}>
                        <div className={style.doctor}>
                            <h2>Dr {prescriptiondetails && prescriptiondetails.created_by.nom} {prescriptiondetails && prescriptiondetails.created_by.prenom}</h2>
                            <h3>{prescriptiondetails && prescriptiondetails.created_by.specilite.nom}</h3>

                            <h3>{prescriptiondetails && prescriptiondetails.created_by.adresse}, &nbsp;
                                {prescriptiondetails && prescriptiondetails.created_by.location.code} &nbsp;
                                {prescriptiondetails && prescriptiondetails.created_by.location.ville}
                            </h3>
                        </div>
                        <div className={style.patient}>
                            <h3>Le {prescriptiondetails && moment(prescriptiondetails.date).format("D MMMM y")}</h3>
                            <h2>M. {prescriptiondetails && prescriptiondetails.patient.nom}&nbsp;
                            {prescriptiondetails && prescriptiondetails.patient.prenom}
                            </h2>
                            <h3>Né le {prescriptiondetails && moment(prescriptiondetails.patient.date_de_naissance).format("DD-MM-YYYY")}</h3>
                        </div>
                    </div>
                    <div className={style.label}>
                        <h3>ordonnance</h3>
                    </div>
                    {prescriptiondetails && prescriptiondetails.traitement.map((el, i) => 
                        <div key={i} className={style.medicament}>
                            <div className={style.item}>
                                <h2>{el.medicament.marque} {el.medicament.dosage} {el.medicament.forme}</h2>
                                <span>{el.posologie.usage}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
