import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const createProfile = createAsyncThunk('patient/createProfile',
  async (profileData, thunkAPI) => {
    const {rejectWithValue, dispatch} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/patient/create/', {
        method: 'POST',
        body: JSON.stringify(profileData),
        headers: { 
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
        },
      });
      const data = await res.json();
      if (res.status === 201) {
        dispatch(modalFunc())
        dispatch(getPatients())
      }
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
})

export const getPatients = createAsyncThunk('patient/getPatients',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/doctorfolder/patientlist/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)

export const getPatientDetails = createAsyncThunk('patient/getPatientDetails',
  async (id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/patient/${id}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)

export const updatePatientDetails = createAsyncThunk('patient/updatePatientDetails',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { id } = userData;
    try {
      const res = await fetch(`https://api.doctodz.com/api/patient/${id}/update/`, {
        method: 'PUT',
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)




export const addPAtientToList = createAsyncThunk('patient/addPAtientToList',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/doctorfolder/addpatient/', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)


export const removePAtientToList = createAsyncThunk('patient/removePAtientToList',
  async (deletedid, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/doctorfolder/removepatient/${deletedid}/`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)

export const getAllPatients = createAsyncThunk('patient/getAllPatients',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/patient/list/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



const patientSlice = createSlice({
    name: 'patient',
    initialState: {
        allpatients: [],
        patients: [],
        cordonnees: [],
        antecedant: [],
        isLoading: false,
        error: null,
        modal: false,
    },
    reducers: {
        modalFunc: (state) => {
          state.modal = !state.modal;
        },
        reset: (state) => {
          state.cordonnees = [];
        },
      },
    extraReducers: {
        // get all patients table
        [getAllPatients.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getAllPatients.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.allpatients = action.payload;
        },
        [getAllPatients.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get patients table
        [getPatients.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getPatients.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.patients = action.payload;
        },
        [getPatients.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // create profile patient
        [createProfile.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [createProfile.fulfilled]: (state, action) => {
            state.isLoading = false;
            // state.patients = action.payload;
        },
        [createProfile.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get patients details
        [getPatientDetails.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getPatientDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.cordonnees = action.payload;
        },
        [getPatientDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // update patients details
        [updatePatientDetails.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [updatePatientDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.cordonnees = action.payload;
        },
        [updatePatientDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const { modalFunc, reset } = patientSlice.actions;

export default patientSlice.reducer;