import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAntecedant,deleteAntecedant, createAntecedant } from "../../../redux/features/LifeStyle/LifeStyleSlice"


import style from './PatientAntecedant.module.css'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useParams } from "react-router-dom";

export default function PatientAntecedant() {

    const { antecedantCh } = useSelector((state) => state.antecedant)

    
    const [modalAntCh, setModalAntCh] = useState(false);
    const [modalAntAll, setModalAntAll] = useState(false);
    const [modalAntFam, setModalAntFam] = useState(false);
    const [modalAntMed, setModalAntMed] = useState(false);

    const [isHover, setIsHover] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()
    const id = params.patientid
    


    const [dataCh, setDataCh] = useState({
        nom : "",
        type: "",
        profile : id,
    })

    const [dataAll, setDataAll] = useState({
        nom : "",
        type: "",
        profile : id,
    })


    const [dataFam, setDataFam] = useState({
        nom : "",
        type: "",
        profile : id,
    })


    const [dataMed, setDataMed] = useState({
        nom : "",
        type: "",
        profile : id,
    })



    useEffect(() => {
        dispatch(getAntecedant(id))
    },  [dispatch, id]);
    
    
    const handleModalAntCh = () => {
        setModalAntCh(!modalAntCh);
    }


    const handleDelete = (item) => {
        dispatch(deleteAntecedant(item.id)).unwrap()
        .then((response) => {
            if (response.success === "delete successful") {
                dispatch(getAntecedant(id))
            }
        })
        .catch((rejectedResponse) => {
          // handle error here
        })
    }



    const handleSaveAntCh = () => {
        dataCh.type = "chirurgical"
        dispatch(createAntecedant(dataCh)).unwrap()
        .then((response) => {
            if (response) {
                dispatch(getAntecedant(id))
                setDataCh({...dataCh, nom : ""})
                setModalAntCh(false)
            }
        })
        .catch((rejectedResponse) => {
          // handle error here
        })
    }
    


    const handleSaveAntAll = () => {
        dataAll.type = "alergie"
        dispatch(createAntecedant(dataAll)).unwrap()
        .then((response) => {
            if (response) {
                dispatch(getAntecedant(id))
                setDataAll({...dataAll, nom : ""})
                setModalAntAll(false)
            }
        })
        .catch((rejectedResponse) => {
          // handle error here
        })
    }


    const handleSaveAntFam = () => {
        dataFam.type = "familiaux"
        dispatch(createAntecedant(dataFam)).unwrap()
        .then((response) => {
            if (response) {
                dispatch(getAntecedant(id))
                setDataFam({...dataFam, nom : ""})
                setModalAntFam(false)
            }
        })
        .catch((rejectedResponse) => {
          // handle error here
        })
    }


    const handleSaveAntMed = () => {
        dataMed.type = "medicaux"
        dispatch(createAntecedant(dataMed)).unwrap()
        .then((response) => {
            if (response) {
                dispatch(getAntecedant(id))
                setDataMed({...dataMed, nom : ""})
                setModalAntMed(false)
            }
        })
        .catch((rejectedResponse) => {
          // handle error here
        })
    }




    const ChirurgicalAnt = antecedantCh.length > 0 ? antecedantCh.filter((item) => {
        if(item.type.toLowerCase() === 'chirurgical'.toLowerCase()) {
            return item.nom
        }
    }).map((item, i) => (
        <ul onClick={() => setIsHover(!isHover)} key={i}>
            {isHover ?
            <div className={style.liste}>
                <div className={style.li}>
                    <li>- {item.nom}</li>
                </div>
                <div className="icon">
                    <DeleteOutlineIcon
                    onClick={() => handleDelete(item)}
                    className={style.deleteicon} style={{fontSize: "18px"}}
                    />
                </div>
            </div>
                :
                <li>- {item.nom}</li>
            }
        </ul>
    )) :
        <ul>
            <li>- aucun</li>
        </ul>


    const alrgieAnt = antecedantCh.length > 0 ? antecedantCh.filter((item) => {
        if(item.type.toLowerCase() === 'alergie'.toLowerCase()) {
            return item.nom
        }
    }).map((item, i) => (
        <ul onClick={() => setIsHover(!isHover)} key={i}>
            {isHover ?
            <div className={style.liste}>
                <div className={style.li}>
                    <li>- {item.nom}</li>
                </div>
                <div className="icon">
                    <DeleteOutlineIcon
                    onClick={() => handleDelete(item)}
                    className={style.deleteicon} style={{fontSize: "18px"}}
                    />
                </div>
            </div>
                :
                <li>- {item.nom}</li>
            }
        </ul>
    )) :
        <ul>
            <li>- aucun</li>
        </ul>


    const fazmiliauxAnt = antecedantCh.length > 0 ? antecedantCh.filter((item) => {
        if(item.type.toLowerCase() === 'familiaux'.toLowerCase()) {
            return item.nom
        }
    }).map((item, i) => (
        <ul onClick={() => setIsHover(!isHover)} key={i}>
            {isHover ?
            <div className={style.liste}>
                <div className={style.li}>
                    <li>- {item.nom}</li>
                </div>
                <div className="icon">
                    <DeleteOutlineIcon
                    onClick={() => handleDelete(item)}
                    className={style.deleteicon} style={{fontSize: "18px"}}
                    />
                </div>
            </div>
                :
                <li>- {item.nom}</li>
            }
        </ul>
    )) :
        <ul>
            <li>- aucun</li>
        </ul>


    const medicauxAnt = antecedantCh.length > 0 ? antecedantCh.filter((item) => {
        if(item.type.toLowerCase() === 'medicaux'.toLowerCase()) {
            return item.nom
        }
    }).map((item, i) => (
        <ul onClick={() => setIsHover(!isHover)} key={i}>
            {isHover ?
            <div className={style.liste}>
                <div className={style.li}>
                    <li>- {item.nom}</li>
                </div>
                <div className="icon">
                    <DeleteOutlineIcon
                    onClick={() => handleDelete(item)}
                    className={style.deleteicon} style={{fontSize: "18px"}}
                    />
                </div>
            </div>
                :
                <li>- {item.nom}</li>
            }
        </ul>
    )) :
        <ul>
            <li>- aucun</li>
        </ul>





    return (
    <Fragment>
        <div className={style.acordion}>
            <div className={style.header}>
                <ExpandMoreIcon />
                <h2>antécédant et mode de vie</h2>
            </div>

            <div className={style.items}>
                    <h3>Antécédant chirurgicaux</h3>
                <div
                onClick={handleModalAntCh}
                className={style.add_button}>
                    <AddIcon className={style.add_icon} />
                    <p>Ajouter un antécédant chirurgical</p>
                </div>
                    {modalAntCh &&
                        <div className={style.addinput}>
                            <input
                            onChange={(e) => setDataCh({...dataCh, nom : e.target.value})}
                            value={dataCh.nom}
                            type="text" placeholder="saisir" />
                            <SaveAltIcon
                            onClick={handleSaveAntCh}
                            className={style.save_icon} style={{fontSize: "22px"}} />
                        </div>
                    }
                    {ChirurgicalAnt}
            </div>


            <div className={style.items}>
                    <h3>Allergies</h3>
                <div
                onClick={() => setModalAntAll(!modalAntAll)}
                className={style.add_button}>
                    <AddIcon className={style.add_icon} />
                    <p>Ajouter une allèrgies</p>
                </div>
                    {modalAntAll &&
                        <div className={style.addinput}>
                            <input
                            onChange={(e) => setDataAll({...dataAll, nom : e.target.value})}
                            value={dataAll.nom}
                            type="text" placeholder="saisir" />
                            <SaveAltIcon
                            onClick={handleSaveAntAll}
                            className={style.save_icon} style={{fontSize: "22px"}} />
                        </div>
                    }
                    {alrgieAnt}
            </div>

            <div className={style.items}>
                    <h3>Antécédant familiaux</h3>
                <div
                onClick={() => setModalAntFam(!modalAntFam)}
                className={style.add_button}>
                    <AddIcon className={style.add_icon} /> 
                    <p>Ajouter un antécédant familiaux </p>
                </div>
                    {modalAntFam &&
                    <div className={style.addinput}>
                        <input
                        onChange={(e) => setDataFam({...dataFam, nom : e.target.value})}
                        value={dataFam.nom}
                        type="text" placeholder="saisir" />
                        <SaveAltIcon
                        onClick={handleSaveAntFam}
                        className={style.save_icon} style={{fontSize: "22px"}} />
                    </div>
                    }
                    {fazmiliauxAnt}
            </div>

            <div className={style.items}>
                    <h3>Antécédant medicaux</h3>
                <div
                onClick={() => setModalAntMed(!modalAntMed)}
                className={style.add_button}>
                    <AddIcon className={style.add_icon} /> 
                    <p>Ajouter un antécédant medicaux</p>
                </div>
                <ul>
                    {modalAntMed &&
                    <div className={style.addinput}>
                    <input
                    onChange={(e) => setDataMed({...dataMed, nom : e.target.value})}
                    value={dataMed.nom}
                    type="text" placeholder="saisir" />
                    <SaveAltIcon
                    onClick={handleSaveAntMed}
                    className={style.save_icon} style={{fontSize: "22px"}} />
                    </div>
                    }
                    {medicauxAnt}
                </ul>
            </div>
        </div>
    </Fragment>
  );
}
