import React, { Fragment } from "react";

import { getPatients, removePAtientToList, reset } from "../../../redux/features/Patient/patientSlice";


import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SummarizeIcon from '@mui/icons-material/Summarize';

import style from './Actions.module.css'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createConsultation, getFiltredConsultation } from "../../../redux/features/Consultation/ConsultationSlice";
import { setCallModal } from "../../../redux/features/Subscription/SubscriptionSlice";



export default function Actions() {


    const { filtredconsultation } = useSelector((state) => state.consultation)
    const { patients } = useSelector((state) => state.patients)
    const { subscriptionlist } = useSelector((state) => state.subscription);

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const params = useParams()
    const id = params.patientid


    const handleClickDelete = () => {
        const newid = parseInt(id, 10)
        const found = patients.find(elem => elem.id === newid)
        const deletedid = found.deletedid
        dispatch(removePAtientToList(deletedid)).then(() => {
            dispatch(getPatients())
        });
        navigate('/dashboard/patients/');
        // dispatch(reset());
    }

    const data = {
        patient : id,
    }

    const startConsultation = () => {
        if (subscriptionlist.includes(2)) {
            dispatch(createConsultation(data)).unwrap()
            .then((originalPromiseResult) => {
              navigate(`/dashboard/patients/${id}/consultation/${originalPromiseResult.id}/`)
            }).then(() => {
                dispatch(getFiltredConsultation(id))
            })
        } else {
            dispatch(setCallModal())
        }
    }
    
    const writeReport = () => {
        if (subscriptionlist.includes(2)) {
            navigate(`/dashboard/patients/${id}/documents/compterendu`)
        } else {
            dispatch(setCallModal())
        }
    }



  return (
    <Fragment>
        {filtredconsultation.length === 0 &&
            <div className={style.actions}>
                    <div className={style.buttons}>
                        <div
                        onClick={startConsultation}
                        className={style.action_button}>
                            <PlayCircleFilledIcon style={{fontSize: "35px"}} className={style.icons} />
                            <p>Commener la consultation</p>
                        </div>
                    </div>
                <div className={style.buttons}>
                    <div
                    onClick={writeReport}
                    className={style.action_button}>
                        <SummarizeIcon style={{fontSize: "35px"}} className={style.icons} />
                        <p>Faire un compte rendu</p>
                    </div>
                </div>
                {/* <div className={style.buttons}>
                    <div className={style.action_button}>
                        <SystemUpdateAltIcon style={{fontSize: "35px"}} className={style.icons} />
                        <p>Importer des documents</p>
                    </div>
                </div> */}
                {/* <div className={style.buttons}>
                    <div className={style.action_button}>
                        <ExitToAppIcon style={{fontSize: "35px"}} className={style.icons} />
                        <p>Exporter le dossier</p>
                    </div>
                </div> */}
                <div className={style.buttons}>
                    <div
                    onClick={handleClickDelete}
                    className={style.action_button}>
                        <DeleteIcon style={{fontSize: "35px"}} className={style.delete_icons} />
                        <div className={style.delete_p} >
                            <p>Suprimmer le patient</p>
                        </div>
                    </div>
                </div>
            </div>
        }
    </Fragment>
  );
}
