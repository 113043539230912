import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { logoutUser } from '../../redux/features/User/authSlice';
import style from './Dashboard.module.css'

import logo from '../../assets/images/logo-docotodz.png'
import avatar from '../../assets/images/avatar.jpg'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import { reset } from '../../redux/features/Patient/patientSlice';
import SearchVerticalBar from '../../Components/SearchVerticalBar/SearchVerticalBar';
import ProPratiqueModal from '../../Components/ProPratiqueModal/ProPratiqueModal';
import { getSubscriptionList } from '../../redux/features/Subscription/SubscriptionSlice';
import ProRdvModal from '../../Components/ProRdvModal/ProRdvModal';





export default function Dashboard() {

  const [menu, setMenu] = useState(false)


  const toggleMenu = () => {
    setMenu(!menu)
  }
  
  const navigate = useNavigate()
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(getSubscriptionList())
  }, [dispatch]);

  const handelClick = (e) => {
    dispatch(logoutUser()).then(() => {
      navigate('/')
      window.location.reload();
    })
  }

  const location = useLocation()
  const { pathname } = location;
  const splitLocation = pathname.split("/");


  const handleClickPatient = () => {
    dispatch(reset())
  }

  return (
    <>
    <div className={style.content}>
      <div className={style.sidebar}>
        <div className={style.image}>
          <Link to="/dashboard/agenda">
            <img src={logo} alt="logo DoctoDz" />
          </Link>
        </div>
        <div className={style.links}>
          <div
            className={splitLocation[2] === 'agenda' ? style.link_active : style.link }>
            <Link to='/dashboard/agenda/'>
              <CalendarMonthIcon style={{fontSize: "50px"}} />
              Agenda
            </Link>
          </div>
          <div
          onClick={handleClickPatient}
          className={splitLocation[2] === 'patients' ? style.link_active : style.link }>
            <Link to='/dashboard/patients/'>
              <PersonIcon style={{fontSize: "50px"}} />
              Patients
            </Link>
          </div>
          <div 
          className={splitLocation[2] === 'parametre' ? style.link_active : style.link }>
            <Link to='/dashboard/parametre/agendaparalmeter/'>
              <SettingsIcon style={{fontSize: "50px"}} />
              Paramètre
            </Link>
          </div>
        </div>
      </div>

    <div className={style.working_space}>
      <div className={style.verticalbar}>
          <SearchVerticalBar />
        <div
        onClick={toggleMenu}
        className={style.avatar}>
          <img src={avatar} alt="avatar de l'utilisateur" />
          <MenuIcon />
            <div 
            className={menu ? style.avatar_menu : style.hide_avatar}
            >
              <ul>
                <li>
                  <PersonIcon style={{fontSize: "18px"}} />
                  <span><Link to='/dashboard'>Mon profile</Link></span>
                </li>
                  <div className={style.trait}></div>
                <li>
                <LogoutIcon style={{fontSize: "18px"}} />
                <span onClick={handelClick} >Logout</span>
                </li>
                  <div className={style.trait}></div>
              </ul>
            </div>
        </div>
      </div>



        <div className={style.sub_sidebar}>
            <Outlet />
        </div>

    </div>

    </div>


    <ProRdvModal />
    <ProPratiqueModal />

    </>
  )
}
