import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Singup from './Pages/Singup/Singup';
import Dashboard from './Pages/Dashboard/Dashboard';
import Agenda from './Components/Agenda/Agenda'
import Patients from './Components/Patients/Patients';
import PatientDetail from './Components/Patients/PatientDetail/PatientDetail';
import Layout from './Components/Layout/Layout';
import Parametre from './Pages/Parametre/Parametre';
import Cordonnees from './Components/Patients/Cordonnees/Cordonnees';
import './App.css';
import Taches from './Components/Patients/Taches/Taches';
import Documents from './Components/Patients/Documents/Documents';
import Historique from './Components/Patients/Historique/Historique';
import Consultation from './Components/Consultation/Consultation';
import MotifConsultation from './Pages/Parametre/MotifConsultation/MotifConsultation';
import OrodonanceDetails from './Pages/OrodonanceDetails/OrodonanceDetails';
import CompteRenduDetails from './Pages/CompteRenduDetails/CompteRenduDetails';
import ReportWrite from './Components/ReportWrite/ReportWrite';
import ConsultationDetails from './Pages/ConsultationDetails/ConsultationDetails';
import SuccesRegistration from './Pages/SuccesRegistration/SuccesRegistration';
import AgnedaParameter from './Pages/Parametre/AgnedaParameter/AgnedaParameter';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { autoLogin, getCurrentUser } from './redux/features/User/authSlice';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import RedirectComponent from './Components/RedirectComponent/RedirectComponent';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ChangeForgotPassword from './Pages/ChangeForgotPassword/ChangeForgotPassword';
import AstDashboard from './Pages/AstDashboard/AstDashboard';



function App() {


  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(autoLogin())
    dispatch(getCurrentUser())
  }, [dispatch]);



  const { user } = useSelector((state) => state.auth);

  
  return (
      <>
        <Layout>
            <div className="App">

                
                <Routes>
                      <Route path="/" element={<RedirectComponent><Home /></RedirectComponent>} />
                      <Route path="/singup" element={<RedirectComponent><Singup /></RedirectComponent>} />
                      <Route path="/succes" element={<RedirectComponent><SuccesRegistration /></RedirectComponent>} />
                      <Route path="/forgot-password/" element={<ForgotPassword />} />
                      <Route path="/change-forgot-password/" element={<ChangeForgotPassword />} />

                      <Route path="/astdashboard/" element={<ProtectedRoute><AstDashboard /></ProtectedRoute>} />



                      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} >
                        <Route index path="/dashboard/agenda" element={<Agenda />} />
                        
                        <Route path="/dashboard/patients" element={<Patients />} />
                        <Route path="/dashboard/patients/:patientid" element={<PatientDetail />} >
                          <Route path="/dashboard/patients/:patientid/consultation/:consultationid" element={<Consultation />} />
                          <Route path="/dashboard/patients/:patientid/coordonnees" element={<Cordonnees />} />
                          <Route path="/dashboard/patients/:patientid/taches" element={<Taches />} />
                          <Route path="/dashboard/patients/:patientid/documents" element={<Documents />} />
                          <Route path="/dashboard/patients/:patientid/documents/ordononce/:ordonanceid/" element={<OrodonanceDetails />} />
                          <Route path="/dashboard/patients/:patientid/documents/compterendu" element={<ReportWrite />} />
                          <Route path="/dashboard/patients/:patientid/documents/compterendu/:compterenduid" element={<CompteRenduDetails />} />
                          <Route path="/dashboard/patients/:patientid/historique" element={<Historique />} />
                          <Route path="/dashboard/patients/:patientid/historique/consultation/:consultationid" element={<ConsultationDetails />} />
                          <Route path="/dashboard/patients/:patientid/historique/consultation/:consultationid/ordonance/:ordonanceid/" element={<OrodonanceDetails />} />
                          <Route path="/dashboard/patients/:patientid/historique/consultation/:consultationid/compterendu/:compterenduid/" element={<CompteRenduDetails />} />
                        </Route>

                        <Route path="/dashboard/parametre" element={<Parametre />}>
                          <Route index path="/dashboard/parametre/agendaparalmeter/" element={<AgnedaParameter />} />
                          <Route path="/dashboard/parametre/motifconsultation/" element={<MotifConsultation />} />
                        </Route>
                      </Route>
                </Routes>
            </div>
        </Layout>
      </>
  );
}

export default App;
