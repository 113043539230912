import * as React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setCallModal } from '../../redux/features/Subscription/SubscriptionSlice';

import style from './ProPratiqueModal.module.css'

import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DiamondIcon from '@mui/icons-material/Diamond';
import { Button } from '@mui/material';


const st = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  height : 324,
  width : 682,
};

export default function ProPratiqueModal() {


  const dispatch = useDispatch()

  const { CallModal } = useSelector((state) => state.subscription);

  const handleClose = () => {
    dispatch(setCallModal())
  }



  return (
    <div>
      <Modal
        open={CallModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={st}>
          <div className={style.container}>
            <div className="titles">
              <div className={style.title1}>
                <LocalFireDepartmentIcon />
                <h2>Passez à la Vitesse Supérieure avec Doctodz ProPratique !</h2>
              </div>
              <div className={style.title2}>
                <DiamondIcon />
                <h2> Optimisez la Gestion de votre Pratique Médicale !</h2>
              </div>
            </div>
            <div className={style.paragraph}>
              <p>Offrez-vous une interface conviviale de suivi des patients. Avec un formulaire dynamique entièrement personnalisable, chaque consultation est adaptée aux besoins de vos patients. Et n'oublions pas notre module d'aide à la prescription.</p>
            </div>
            <div className="button">
            <a href="https://info.doctodz.com/demande-de-devis/" target="_blank" rel="noopener noreferrer" >
              <Button
                  // onClick={handleSearchClick}
                  className={style.button}
                    variant="contained"
                    style={{ background : "#F87575", borderRadius : 2, fontWeight : 'bold'}}
              > Demander un devis pour ProPratique
              </Button>
            </a>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}