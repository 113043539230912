import { Button } from '@mui/material'
import React from 'react'

import AddIcon from '@mui/icons-material/Add';


import style from './MotifConsultation.module.css'

export default function MotifConsultation() {
  return (
    <div className={style.container}>
      <div className={style.button}>
        <Button variant="contained" startIcon={<AddIcon />}>
          Ajouté un motif
        </Button>
      </div>
    </div>
  )
}
