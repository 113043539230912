import style from './PatienTable.module.css'

import { getPatients } from '../../../redux/features/Patient/patientSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Fragment } from 'react-is';
import { useNavigate } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';

export default function PatienTable() {


  const { patients } = useSelector((state) => state.patients)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getPatients())
  },  [dispatch]);
  

  const hadleClick = (value) => {
    navigate(`/dashboard/patients/${value.id}/coordonnees/`)
  }


  const rows = patients;

  const columns = [
    { field: 'id', headerName: 'Identifiant', width: 120 },
    { field: 'nom', headerName: 'Nom', width: 180 },
    { field: 'prenom', headerName: 'Prénom', width: 180 },
    { field: 'sexe', headerName: 'Sexe', width: 180 },
    { field: 'phone_number', headerName: 'Numéro de téléphone', width: 180 },
    { field: 'email', headerName: 'Email', width: 180 },
    { field: 'date_de_naissance', headerName: 'Date De Naissance', width: 180 },
    { field: 'ville_de_naissance', headerName: 'Ville De Naissance', width: 180 },
  ];




  return (
    <Fragment>
      <div style={{height : 600 , width: "100%"}} >
        <DataGrid 
          onRowClick={(value) => hadleClick(value)}
          rows={rows} columns={columns}
            pageSize={50}
            rowsPerPageOptions={[50]}
        />
      </div>
    </Fragment>
  )
}
