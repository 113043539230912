import React, { Fragment, useState } from 'react'
import style from './ForgotPassword.module.css'

import { Link, useNavigate } from 'react-router-dom';

import EmailIcon from "@mui/icons-material/Email";
import { CircularProgress, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { sendForgotPassword } from '../../redux/features/User/authSlice';





export default function ForgotPassword() {


  const { isLoading } = useSelector((state) => state.auth);

  const [validation, setValidation] = useState("")


  const [data, setData] = useState({
    email : ""
  })


  const handleChangeEmail = (email) => {
    setData({...data, email : email.target.value})
  }


  const disptach = useDispatch()
  const navigate = useNavigate()


  // /change-forgot-password/

  const handleClickSubmit = () => {
    if (!data.email) {
      setValidation('Entré votre adresse email svp')
    } else {
      setValidation('')
      disptach(sendForgotPassword(data)).then((value) => {
        navigate('/change-forgot-password/')
      })
    }
  }



  return (
    <Fragment>
      <div className={style.content}>
          <div className={style.form}>
            <h1>Veuillez saisir votre adresse émail</h1>

            <TextField
                style={{ width : 400, marginBottom : "40px" }}
                className={style.input} 
                onChange={email => handleChangeEmail(email)}
                label="Votre adresse émail"
                InputProps={{
                  startAdornment: (
                    <EmailIcon />
                  ),
                }}
            >
            </TextField>

            {validation &&
              <div className={style.danger}>
                <p>{validation}</p>
              </div>
            }
            {isLoading ?
              <CircularProgress />
              :
              <div
              onClick={() => handleClickSubmit()}
              className={style.btn}>
                <input
                type="submit" value="Envoyer"/>
              </div>
            }
          </div>
      </div>
    </Fragment>
  )
}
