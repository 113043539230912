import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';



import style from './ToolBar.module.css'

import MedicationIcon from '@mui/icons-material/Medication';
import BiotechIcon from '@mui/icons-material/Biotech';
import PanoramaWideAngleIcon from '@mui/icons-material/PanoramaWideAngle';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { deletePrescription, getPrescription, getPrescriptionDetails } from '../../../../redux/features/Prescription/PrescriptionSlice';
import { useParams } from 'react-router-dom';




export default function ToolBar({ setPage }) {

  const { listprescription } = useSelector((state) => state.prescription)

  const dispatch = useDispatch()
  const params = useParams()

  const patient_pk = params.patientid
  const consultation_pk = params.consultationid




  const dataPresq = {
    patient_pk : patient_pk,
    consultation_pk : consultation_pk
  }

  useEffect(() => {
    dispatch(getPrescription(dataPresq))
  },  [dispatch]);
  
  
  const handleDelete = (item) => {
    dispatch(deletePrescription(item.id)).then(() => {
        dispatch(getPrescription(dataPresq))
    })
  }


  const handleClickOrdo = (item) => {
    setPage(5)
    dispatch(getPrescriptionDetails(item.id))
    // console.log(item.id);
  }

  const listData = listprescription.map((item , i) => (
    <div key={i} className={style.list_container}>
    <div className={style.liste}>
      <div className="icon">
        <MedicationIcon />
      </div>
      <div className={style.texte}>
        <h2>Ordonnance de médicaments</h2>
      </div>
      <div className={style.action_icons}>

        <VisibilityIcon
          onClick={() => handleClickOrdo(item)}
          style={{fontSize: "20px"}}  />

        <DeleteOutlineIcon
        onClick={() => handleDelete(item)}
        className={style.deleteIcon} style={{fontSize: "20px"}} />
      </div>
      </div>
    </div>
  ))


  return (
    <Fragment>
        <div className={style.toolbar}>
            <div
            onClick={() => setPage(1)}
            className={style.button}>
              <MedicationIcon />
              <p>Ordonnance de médicaments</p>
            </div>
            <div
            onClick={() => setPage(2)}
            className={style.button}>
              <BiotechIcon />
              <p>Ordonnance de biologie</p>
            </div>
            <div 
            onClick={() => setPage(3)}
            className={style.button}>
              <PanoramaWideAngleIcon />
              <p>Ordonnance d’imagerie</p>
            </div>
            <div
            onClick={() => setPage(4)}
            className={style.button}>
              <AccessibilityIcon />
              <p>Soins paramedicaux</p>
            </div>
        </div>

    {listData && listData}

    </Fragment>
  )
}
