import React, { useEffect } from 'react'
import { Fragment } from 'react'
import style from './ObservationConsulation.module.css'

import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNomData } from '../../../redux/features/ModelData/ModelDataSlice';
import { useParams } from 'react-router-dom';
import { getListDonnePatient, getPrevDonnePatient, updateListDonneAdd, updateListDonneRemove } from '../../../redux/features/DonneeSuivie/DonneeSuivieSlice';



import GradeIcon from '@mui/icons-material/Grade';
import StarBorderIcon from '@mui/icons-material/StarBorder';


import PreviousVal from './PreviousVal/PreviousVal'

export default function ObservationConsulation({observation, setObservation ,formData, setFormData}) {

  const { nomData } = useSelector((state) => state.modeldata)
  const { donnepatienlist } = useSelector((state) => state.donnesuivie)



  const [addInput, setAddInput] = useState(false)
  const [validation, setValidation] = useState(false)
  const [suggestion, setSuggestion] = useState([])

  const dispatch = useDispatch()


  const params = useParams()
  const patient_pk = params.patientid
  const consultation_pk = params.consultationid





  useEffect(() => {
    dispatch(getNomData())
    dispatch(getListDonnePatient(patient_pk))
  },  [dispatch]);



  useEffect(() => {
    let newList = []
    if (donnepatienlist.donnee !== 0) {
      if (donnepatienlist.donnee !== undefined) {
        donnepatienlist.donnee.map(el => {
          let object = {
            id : el.id,
            nom : el.nom,
            saved : true,
            unite : el.unite,
            data_list : donnepatienlist.id,
            value : "",
            patient_profile : patient_pk,
            consultation : consultation_pk,
          }
          newList.push(object)
          setFormData({...formData, donnee : newList})
        })
      }
    }
  },  [donnepatienlist.donnee]);







  const addDataHandler = () => {
    setAddInput(!addInput)
    setSuggestion([])
    setValidation('')
  }

  const onChangeHandler = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = nomData.filter(nomdata => {
        const regex = new RegExp(`${text}`, "gi");
        return nomdata.nom.match(regex)
      })
    }
    setSuggestion(matches)
  }


  const onSuggestHandler = (suggestion) => {
    const object = {
      id : suggestion.id,
      nom : suggestion.nom,
      saved : false,
      unite : suggestion.unite,
      data_list : donnepatienlist.id,
      value : "",
      patient_profile : patient_pk,
      consultation : consultation_pk,
    }


    let newList = [...formData.donnee]
    const isFound = newList.some(element => {
    if (element.id === suggestion.id) {
        return true;
      }
      return false;
    });
    if (isFound) {
      setValidation("L'élément que vous avez choisi existe déja")
    } else {
      newList.push(object)
      setFormData({...formData, donnee : newList})
      setValidation('')
      setAddInput('')
      setSuggestion([])
    }
  }


  const hadleChangeInput = (e) => {
    let newFormData = [...formData.donnee]
    const fond = newFormData.find(element => element.nom === e.target.name)
    fond.value = e.target.value
  }


  const handleClickSave = (e, el) => {
    e.preventDefault()
    const fond = formData.donnee.find(elem => elem.id === el.id)
    fond.saved = false
    const newForm = {...formData}
    setFormData(newForm)
    const dataa = {
      listId : donnepatienlist.id,
      donnee : [
        {
          id : fond.id
        }
      ]
    }
    dispatch(updateListDonneRemove(dataa))
  }
  
  
  const handleClickUnSave = (e, el) => {
    e.preventDefault()
    const fond = formData.donnee.find(elem => elem.id === el.id)
    fond.saved = true
    const newForm = {...formData}
    setFormData(newForm)
    const dataa = {
      listId : donnepatienlist.id,
      donnee : [
        {
          id : fond.id
        }
      ]
    }
    dispatch(updateListDonneAdd(dataa))
  }


  return (
    <Fragment>
        <div className={style.title}>
          <h2>Observation médical</h2>
        </div>
        <form>
          <div className={style.forms}>
              <div className={style.motif_input}>
                <h3>Motif</h3>
                <textarea
                onChange={(e) => setObservation({...observation, motif : e.target.value})}
                rows="4" cols="30" />
              </div>

              <div className={style.interagation_exam}>
                <h3>Interrogatoire et examen</h3>
                <textarea
                onChange={(e) => setObservation({...observation, interogation : e.target.value})}
                rows="4" cols="30" />
              </div>
              <div className={style.conclusion}>
                <h3>Conclusion</h3>
                <textarea
                onChange={(e) => setObservation({...observation, conclusion : e.target.value})}
                rows="4" cols="30" />
              </div>




              <div className={style.donnesuivie}>
                <h3>Donnes de suivie</h3>

                {formData.donnee.map((el, i) => 
                    <div key={i} className={style.subform}>



                    {el.saved === true ?
                    <div className={style.saveButton}>
                      <GradeIcon 
                        onClick={(e) => handleClickSave(e, el)}
                      />
                    </div>
                      :
                    <div className={style.saveButton}>
                      <StarBorderIcon
                        onClick={(e) => handleClickUnSave(e, el)}
                      />
                    </div>
                    }


                      <h4>{el.nom}</h4>
                      <div className={style.subform_input}>
                        <input
                        onChange={(e) => hadleChangeInput(e)}
                        type="text"
                        name={el.nom}
                        placeholder="Saisir" />
                      </div>

                        <p>{el.unite}</p>

                        {/* hena  */}
                        <div className="previousValue">
                          <PreviousVal id={el.id} />
                        </div>


                    </div>
                )}
              {validation &&
                <div className={style.validation}>
                  <p>{validation}</p>
                </div>
              }
              </div>
              {addInput &&
              <div>
                <div className={style.allsuggestion}>
                  {suggestion && suggestion.map((suggestion,i) => 
                  <div
                  onClick={() => onSuggestHandler(suggestion)}
                  key={i}
                  className={style.suggestion}
                  >{suggestion.nom}</div>
                  )}
                </div>
                <div className={style.inputAddData}>
                  <input
                  onChange={e => onChangeHandler(e.target.value)}
                  type="text" placeholder="Rechercher" />
                </div>

              </div>
              }
              <div
              onClick={addDataHandler}
              className={style.adddata}>
                <AddIcon style={{fontSize: "15px"}} />
                <p>Ajouter une donnée</p>
              </div>
          </div>
        </form>
    </Fragment>
  )
}
