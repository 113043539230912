import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const getPrevDonnePatient = createAsyncThunk('donnesuivie/getPrevDonnePatient',
async (listid, thunkAPI) => {
  const {rejectWithValue} = thunkAPI;
  try {
    const res = await fetch(`https://api.doctodz.com/api/donnee/listvaleurdonne/${listid}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `token ${localStorage.getItem('token')}`,
        "Accept": "application/json",
      },
    });
    const data = await res.json();
    return data
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
)


export const getListDonnePatient = createAsyncThunk('donnesuivie/getListDonnePatient',
async (patient_pk, thunkAPI) => {
  const {rejectWithValue} = thunkAPI;
  try {
    const res = await fetch(`https://api.doctodz.com/api/donnee/listdonneepatient/${patient_pk}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `token ${localStorage.getItem('token')}`,
        "Accept": "application/json",
      },
    });
    const data = await res.json();
    return data
  } catch (error) {
    return rejectWithValue(error.message);
  }
}
)

export const insertValueDonne = createAsyncThunk('donnesuivie/insertValueDonne',
  async (formData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/donnee/createvaleurdonne/", {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const insertObservation = createAsyncThunk('donnesuivie/insertObservation',
  async (observation, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/donnee/createobservation/", {
        method: 'POST',
        body: JSON.stringify(observation),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



export const updateListDonneAdd = createAsyncThunk('donnesuivie/updateListDonneAdd',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { listId } = dataa;
    try {
      const res = await fetch(`https://api.doctodz.com/api/donnee/updatedonneepatientadd/${listId}/`, {
        method: 'PUT',
        body: JSON.stringify(dataa),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



export const updateListDonneRemove = createAsyncThunk('donnesuivie/updateListDonneRemove',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { listId } = dataa;
    try {
      const res = await fetch(`https://api.doctodz.com/api/donnee/updatedonneepatientaremove/${listId}/`, {
        method: 'PUT',
        body: JSON.stringify(dataa),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)





const donneSuivieSlice = createSlice({
    name: 'donnesuivie',
    initialState: {
      donnepatienlist: [],
      createdvalue: [],
      previousdata: [],
      observation: {},
      updateresponse : "",
      isLoading: false,
      error: null,
    },
    reducers: {
    },
    extraReducers: {
      // get patients table
      [getListDonnePatient.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [getListDonnePatient.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.donnepatienlist = action.payload;
      },
      [getListDonnePatient.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // get create value donne patient
      [insertValueDonne.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [insertValueDonne.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.createdvalue = action.payload;
      },
      [insertValueDonne.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // create observation de consultation
      [insertObservation.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [insertObservation.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.observation = action.payload;
      },
      [insertObservation.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // get previous data
      [getPrevDonnePatient.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [getPrevDonnePatient.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.previousdata = action.payload;
      },
      [getPrevDonnePatient.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // get update list donne suivie remove
      [updateListDonneRemove.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [updateListDonneRemove.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.updateresponse = action.payload;
      },
      [updateListDonneRemove.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // get update list donne suivie add
      [updateListDonneAdd.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [updateListDonneAdd.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.updateresponse = action.payload;
      },
      [updateListDonneAdd.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
    }
});


// export const { searchInputFunc } = patientDataSlice.actions;

export default donneSuivieSlice.reducer;
