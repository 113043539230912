import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const getMedicaments = createAsyncThunk('prescription/getMedicaments',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/prescription/listmedicaments/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



export const getPosologie = createAsyncThunk('prescription/getPosologie',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/prescription/listposologie/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)


export const createPrescription = createAsyncThunk('prescription/createPrescription',
  async (ordoData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/prescription/createprescription/", {
        method: 'POST',
        body: JSON.stringify(ordoData),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



export const getPrescription = createAsyncThunk('prescription/getPrescription',
  async (oData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { patient_pk ,consultation_pk } = oData;
    try {
      const res = await fetch(`https://api.doctodz.com/api/prescription/listprescription/${patient_pk}/${consultation_pk}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



export const deletePrescription = createAsyncThunk('prescription/deletePrescription',
    async (id, thunkAPI) => {
      const {rejectWithValue} = thunkAPI;
      try {
        const res = await fetch(`https://api.doctodz.com/api/prescription/${id}/deleteprescription/`, {
          method: 'DELETE',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
)



export const getPrescriptionDetails = createAsyncThunk('prescription/getPrescriptionDetails',
  async (id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    // const { patient_pk ,consultation_pk } = oData;
    try {
      const res = await fetch(`https://api.doctodz.com/api/prescription/detailsprescription/${id}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)




export const sharePrescription = createAsyncThunk('prescription/sharePrescription',
  async (presobj, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/prescription/sahredprescriptioncreate/", {
        method: 'POST',
        body: JSON.stringify(presobj),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



const PrescriptionSlice = createSlice({
    name: 'prescription',
    initialState: {
      medicaments: [],
      posologies: [],
      prescription : [],
      listprescription : [],
      prescriptiondetails : "",
      isLoading: false,
      error: null,
    },
    reducers: {},
    extraReducers: {
      // get patients table
      [getMedicaments.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [getMedicaments.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.medicaments = action.payload;
      },
      [getMedicaments.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // get posologie list 
      [getPosologie.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [getPosologie.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.posologies = action.payload;
      },
      [getPosologie.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // create prescription  
      [createPrescription.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [createPrescription.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.prescription = action.payload;
      },
      [createPrescription.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // get list prescription  
      [getPrescription.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [getPrescription.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.listprescription = action.payload;
      },
      [getPrescription.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload.id;
      },
      // delete prescription  
      [deletePrescription.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [deletePrescription.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.prescription = action.payload;
      },
      [deletePrescription.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
      // get prescription details
      [getPrescriptionDetails.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [getPrescriptionDetails.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.prescriptiondetails = action.payload;
      },
      [getPrescriptionDetails.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
    }
});




export default PrescriptionSlice.reducer;
