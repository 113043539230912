import React, { useEffect } from 'react'
import { Fragment } from 'react'


import style from './SearchVerticalBar.module.css'
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPatients, getPatientDetails } from '../../redux/features/Patient/patientSlice';
import { useState } from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { getAntecedant } from '../../redux/features/LifeStyle/LifeStyleSlice';



export default function SearchVerticalBar() {


  const { patients } = useSelector((state) => state.patients)
  const dispatch = useDispatch()
  const navigate = useNavigate()



  useEffect(() => {
    dispatch(getPatients())
  },  [dispatch]);


  const handleChangeName = (patient) => {
    if (patient) {
      navigate(`/dashboard/patients/${patient.id}/coordonnees`)
    }
  }
  




  return (
    <Fragment>
        <Autocomplete
            freeSolo
            onChange={(event, patient) => handleChangeName(patient)}
            disablePortal
            id="combo-box-demo"
            options={patients}
            getOptionLabel={(option) => option.nom + ' ' + option.prenom}
            sx={{ width: '50%' }}
            style={{ marginBottom: '0px', background : '#fff' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nom de votre patient"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
        />
    </Fragment>
  )
}
