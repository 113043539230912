import React, { useRef, useState } from 'react'
import { Fragment } from 'react'
import style from './Consultation.module.css'
import PatientOutletHeader from '../Patients/PatientOutletHeader/PatientOutletHeader'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HistoriqueConsultation from './HistoriqueConsultation/HistoriqueConsultation';
import ObservationConsulation from './ObservationConsulation/ObservationConsulation';
import PlanConsultation from './PlanConsultation/PlanConsultation';
import { useDispatch } from 'react-redux';
import { insertObservation, insertValueDonne } from '../../redux/features/DonneeSuivie/DonneeSuivieSlice';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getFiltredConsultation, updateConsultation } from '../../redux/features/Consultation/ConsultationSlice';



export default function Consultation() {


  const params = useParams()
  const navigate = useNavigate()
  const consultation_pk = params.consultationid
  const patient_pk = params.patientid


  const [toggle, setToggle] = useState(false)
  const [formData, setFormData] = useState({
    donnee : [],
  })
  const [observation, setObservation] = useState({
    motif : '',
    interogation : '' ,
    conclusion : '',
    consultation : consultation_pk
  })

  const dispatch = useDispatch()



  const handleClickHistorique = () => {
    setToggle(!toggle)
  }

  const returnHistorique = () => {
    if(toggle) {
      return (
        <div className={style.historique}>
          <div
            onClick={handleClickHistorique}
            className={style.rangeoire}>
              <ArrowForwardIosIcon style={{fontSize: "15px"}} />
          </div>
          <div className={style.historique_content}>
            <HistoriqueConsultation />
          </div>
        </div>
      )
    } else {
      return (
        <div
        onClick={handleClickHistorique}
        className={style.tiroire}>
          <ArrowBackIosIcon style={{fontSize: "15px"}} />
        </div>
      )
    }
  }



  const handleSubmit = () => {
    dispatch(insertValueDonne(formData.donnee)).then(() => {
      dispatch(insertObservation(observation)).then(() => {
        dispatch(updateConsultation(observation)).then(() => {
          dispatch(getFiltredConsultation(patient_pk)).then(() => {
            navigate(`/dashboard/patients/${patient_pk}/coordonnees`)
          })
        })
      })
    })
  }

  return (
    <Fragment>
      <div className={style.consulation}>
        {/* <div className={style.patientoutletheader}>
          <PatientOutletHeader />
        </div> */}
        <div className={style.tools_card_container}>
          <div className={style.observation}>
            <ObservationConsulation observation={observation} setObservation={setObservation}
                          formData={formData} setFormData={setFormData}/>
          </div>
          <div className={toggle ? style.hide : style.pland_de_soin}>
            <PlanConsultation />
          </div>
          {returnHistorique()}
        </div>
        <div className={style.finishbar}>
          <button
          onClick={handleSubmit}
          >Terminer</button>
        </div>
      </div>
    </Fragment>
  )
}
