import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const getNomData = createAsyncThunk('modeldata/getNomData',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/donnee/nomdedonneesuivie/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)







const patientDataSlice = createSlice({
    name: 'modeldata',
    initialState: {
      nomData: [],
      isLoading: false,
      error: null,
      searchInput: false,
    },
    reducers: {
      searchInputFunc: (state) => {
        state.searchInput = !state.searchInput;
      },
    },
    extraReducers: {
      // get nomdedonneesuivie data
      [getNomData.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
      },
      [getNomData.fulfilled]: (state, action) => {
          state.isLoading = false;
          state.nomData = action.payload;
      },
      [getNomData.rejected]: (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
      },
    }
});


export const { searchInputFunc } = patientDataSlice.actions;

export default patientDataSlice.reducer;