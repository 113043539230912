import React, { Fragment } from 'react'


import style from "./SuccesRegistration.module.css"

export default function SuccesRegistration() {
  return (
    <Fragment>
        <div className={style.container}>
          <h1>Félicitation Votre Compte A Bien été Créé</h1>
          <p>l'équipe doctodz vous contactera pour vous accompagner à la création et le paramétrage de votre de votre profile</p>
        </div>
    </Fragment>
  )
}
