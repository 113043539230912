import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getAntecedant = createAsyncThunk('antecedant/getAntecedant',
  async (id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/donnee/antecedantlist/${id}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const createAntecedant = createAsyncThunk('antecedant/createAntecedant',
  async (dataant, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/donnee/antecedantcreate/", {
        method: 'POST',
        body: JSON.stringify(dataant),
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)


export const deleteAntecedant = createAsyncThunk('antecedant/deleteAntecedant',
  async (id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/donnee/antecedantdelete/${id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)




const antecedantSlice = createSlice({
    name: 'antecedant',
    initialState: {
        antecedantCh: [],
        newAntecedant: [],
        isLoading: false,
        error: null,
        searchInput: false,
    },
    reducers: {},
    extraReducers: {
        // create antecedant list
        [createAntecedant.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [createAntecedant.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.newAntecedant = action.payload;
        },
        [createAntecedant.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // delete antecedant list
        [deleteAntecedant.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [deleteAntecedant.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [deleteAntecedant.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get antecedant list
        [getAntecedant.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [getAntecedant.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.antecedantCh = action.payload;
        },
        [getAntecedant.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { searchInputFunc } = patientDataSlice.actions;

export default antecedantSlice.reducer;



