import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";



export default function Calendar({ startDate, setStartDate }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#00A6FB",
        dark: "#00A6FB",
        contrastText: "white",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={startDate}
          onChange={(date) => {
            const newdate = new Date(date)
            const momentDate = moment(newdate)
            setStartDate(momentDate);
          }}
          renderInput={() => {}}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}