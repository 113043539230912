import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './SideBarParameter.module.css'


import ShowChartIcon from '@mui/icons-material/ShowChart';



export default function SideBarParameter() {


  const location = useLocation()
  const { pathname } = location;
  const splitLocation = pathname.split("/");




  return (
    <div className={style.sidebarparameter}>
      <div className={style.links}>
      {/* <Link to='/dashboard/parametre/motifconsultation/'>
          <div
            className={splitLocation[3] === 'motifconsultation' ? style.link_active : style.link }>
              <ShowChartIcon className={style.dataicon} style={{fontSize: "28px"}} />
              Motif de consultation
          </div>
        </Link> */}
      <Link to='/dashboard/parametre/agendaparalmeter/'>
          <div
            className={splitLocation[3] === 'agendaparalmeter' ? style.link_active : style.link }>
              <ShowChartIcon className={style.dataicon} style={{fontSize: "28px"}} />
              Paramètre d'agenda
          </div>
        </Link>
      </div>
    </div>
  )
}
