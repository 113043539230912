import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUSer } from "../../redux/features/User/authSlice";
import img1 from "../../assets/images/Logo-doctodz-pro.png"


import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from "@mui/icons-material/Email";

import style from "./Singup.module.css";
import { Link, useNavigate } from "react-router-dom";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';



function Singup() {


  const [validation, setValidation] = useState("");
  const [acceptAgree, setAcceptAgree] = useState(false);

  const navigate = useNavigate()

  const form = useRef();
  const email = useRef(null);
  const phone = useRef(null);
  const password = useRef(null);

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);




  const [data, setData] = useState({
    phone : null,
    email : null,
    password : null,
  })


  const handleChangePassword = (elem) => {
    setData({...data, password : elem.target.value})
  }

  const handleChangeEmail = (email) => {
    setData({...data, email : email.target.value})
  }


  const handleChangePhone = (phone) => {
    setData({...data, phone : phone.target.value})
  }




  const handelSubmit = (e) => {
    e.preventDefault();
    setValidation("");
    if ((data.password.length || data.password.length) < 6) {
      setValidation("Le mot de passe doit comporter au moins 6 caractères ");
      return;
    } else if (!data.phone) {
      setValidation(
        "Vous avez oublier de saisir votre numéro de téléphone"
      );
      return;
    } else if (!data.email) {
      setValidation(
        "Vous avez oublier de saisir votre émail"
      );
      return;
    } else {
      try {
        dispatch(registerUSer(data)).unwrap().then((originalPromiseResult) => {
            if (originalPromiseResult === 'succes') {
                navigate('/succes')
            } else {
              setValidation("Cette adresse email est déjà associée à un compte");
            }
          })
          .catch((rejectedValueOrSerializedError) => {
            setValidation("Identifiants invalid");
          });
        setValidation("");
      } catch (error) {
        console.log(error);
      }
    }

  };

  const onCheckboxClick = () => {
    setAcceptAgree(!acceptAgree)
  };

  return (
    <>
      <div className={style.content}>
        <div className={style.logo}>
        <img src={img1} alt="" />
        </div>
        <div className={style.caret}>
          <h2>J'ai déjà un compte Doctodz</h2>
          <Link to="/">Se connecter</Link>
        </div>
        <form onSubmit={handelSubmit} ref={form}>
          <div className={style.form}>
            <h1>Nouveau sur Doctodz ?</h1>
            <p>Saisissez vos informations pour continuer.</p>


            <TextField
                style={{ width : 400, marginBottom : "30px" }}
                className={style.input} 
                onChange={phone => handleChangePhone(phone)}
                label="Votre numéro de téléphone"
                InputProps={{
                  startAdornment: (
                    <LocalPhoneIcon />
                  ),
                }}
            >
            </TextField>




            <TextField
                style={{ width : 400, marginBottom : "30px" }}
                className={style.input} 
                onChange={email => handleChangeEmail(email)}
                label="Votre adresse émail"
                InputProps={{
                  startAdornment: (
                    <EmailIcon />
                  ),
                }}
            >
            </TextField>


            <FormControl
                style={{ width : 400, marginBottom : "30px" }}
                className={style.input} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                    <OutlinedInput
                      onChange={(elem) => handleChangePassword(elem)}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Mot de passe"
                    />
            </FormControl>



            {validation &&
              <div className={style.danger}>
                <p>{validation}</p>
              </div>
            }

            <div className={style.check_box}>
              <input onClick={onCheckboxClick} type="checkbox" id="check" />
              <label htmlFor="check">
                Je déclare avoir pris connaissance et accepter les
                <span>
                  <a target="_blank" href="https://photo-docteur-algerie-doctodz.s3.eu-west-3.amazonaws.com/informations-legal/professionnel/CONDITIONS+D%E2%80%99UTILISATION+DES+SERVICES+Freemium.pdf"> Conditions d'Utilisation et</a>
                  <a target="_blank" href="https://photo-docteur-algerie-doctodz.s3.eu-west-3.amazonaws.com/informations-legal/professionnel/ACCORD+SUR+LA+PROTECTION+DES+DONNEES+A+CARACTERE+PERSONNEL+PRO.pdf"> l'Accord sur la protection des données à caractère personnel de DoctoDz .</a>
                </span>
              </label>
            </div>
            <div className={style.btn}>
              <button
                disabled={!acceptAgree}
                className={acceptAgree ? style.enabled : style.disabled}
                type="submit"
              >
                S'INSCRIRE
              </button>
            </div>
          </div>
        </form>
        
        <div className={style.consentment}>
            <p>Vos données sont sécurisées et stockées chez AWS à Paris, un datacenter certifié HDS.</p>
            <p>تتم حفظ بياناتك بأمان في مركز بيانات أو دبليو إس في باريس، وهو معتمد بموجب إتش دي إس.</p>
        </div>

      </div>
    </>
  );
}

export default Singup;
