import React, { useRef } from 'react'

import style from './AgendaModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import closeIcon from "../../../assets/images/close.svg";
import useOutSideClick from "../TableTime/Utils";
import { useState } from 'react';
import { Fragment } from 'react';


import { modalFunc } from "../../../redux/features/Patient/patientSlice";
import { createCloseTimeSlot, getAppoitment, getClosedTimeSlot, planifyAppoitment } from '../../../redux/features/Appoitment/AppoitmentSlice';
import { Autocomplete, TextField } from '@mui/material';


export default function AgendaModal({ setModal, dateTime }) {


  const { patients } = useSelector((state) => state.patients)
  const { moticonsultation } = useSelector((state) => state.consultation)


  const dispatch = useDispatch()
  const popover = useRef();
  useOutSideClick(popover, () => setModal(false));


  const [page, setPage] = useState(0)
  const [validation, setValidation] = useState([])


  const [formData, setFormData] = useState({
    patient : '',
    motif_consultation : '',
    date : dateTime.date,
    from_time : dateTime.from_time,
    to_time : dateTime.to_time
  })



  const handleChangeMotif = (event, motif) => {
    setFormData({...formData, motif_consultation : motif.id })
  }


  const handleChangePatient = (event, patient) => {
    setFormData({...formData, patient : patient.id })
  }



  const handleValidate = () => {
    if(formData.patient && formData.motif_consultation && formData.from_time && formData.to_time && formData.date) {
      dispatch(planifyAppoitment(formData)).then(() => {
        dispatch(getAppoitment())
      })
      setModal(false)
      } else {
      setValidation('Veuillez Remplire tout les champs du formulaire')
    }
  }


  const handleCloseTime = () => {
      const dataa = {
        time_slot : dateTime.timeid,
        created_at : dateTime.date
      }
      dispatch(createCloseTimeSlot(dataa)).then(() => {
        dispatch(getClosedTimeSlot())
        setModal(false)
      })
  }




  const displayPage = () => {
    if(page === 0) {
      return (
        <Fragment>
          <div className={style.buttons}>
              <button
              onClick={() => setPage(1)}
              >
                Planifier un rendez-vous pour un patient
              </button>
              <button
              onClick={handleCloseTime}
              >
                Fermé cette heure
              </button>
          </div>
          <div className={style.validation2}>
              <span>
                {validation}
              </span>
          </div>
        </Fragment>
      )
    } else if(page === 1) {
      return (
        <Fragment>
          <div className={style.form}>

            <Autocomplete
                onChange={(event, motif) => {
                  handleChangeMotif(event ,motif);
                }}
                className={style.input}
                disablePortal
                id="motif"
                options={moticonsultation}
                getOptionLabel={option => option.nom}
                style={{ marginBottom : '22px' }}
                renderInput={params => (
                  <TextField
                  {...params} value="test" variant="outlined" fullWidth />
                  )}
            />


            <Autocomplete
                onChange={(event, patient) => {
                    handleChangePatient(event ,patient);
                  }}
                className={style.input}
                disablePortal
                id="patient"
                options={patients}
                getOptionLabel={option => option.nom + ' ' + option.prenom}
                style={{ marginBottom : '22px' }}
                renderInput={params => (
                  <TextField
                  {...params} value="test" variant="outlined" fullWidth />
                )}
            />

            {/* <input
              value={text}
              onChange={e => onChangeHandler(e.target.value)}
            placeholder="Nom ou email de votre patient" type="text" /> */}



            <div className={style.validation}>
              <span>
                {validation}
              </span>
            </div>

          </div>


          <div className={style.button}>
            <button
            onClick={handleValidate}
            >Confirmé le rendez-vous </button>
          </div>
          {/* {dateTime.time} */}
        </Fragment>
      )
    }
  }



  return (
    <div className={style.overlay}>
      <div ref={popover} className={style.modalContainer}>
        <div className="img">
          <img
              className={style.closeBtn}
              alt="close "
              src={closeIcon}
              onClick={() => setModal(false)}
            />
        </div>

          <div className={style.pages}>
            {displayPage()}
          </div>

      </div>
    </div>
  )
}
