import React from 'react'
import {useSelector} from "react-redux"
import { Navigate } from "react-router-dom"

const RedirectComponent = ({children}) => {

    const { isAuthenticated, user } = useSelector((state) => state.auth);


    if(isAuthenticated) {
      if (user.role === "AST") {
        return <Navigate to="/astdashboard/" replace />
      } else if (user.role === "DOC") {
        return <Navigate to="/dashboard/agenda" replace />
      }
    }
  return children

};

export default RedirectComponent;