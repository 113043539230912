import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import style from './SideBarDetails.module.css'
import PatientCard from '../PatientCard/PatientCard'
import PatientAntecedant from '../PatientAntecedant/PatientAntecedant'


import moment from 'moment'
import 'moment/locale/fr';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from 'react-redux'
import { createConsultation, getFiltredConsultation } from '../../../redux/features/Consultation/ConsultationSlice'


export default function SideBarDeatils() {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");


    const { filtredconsultation } = useSelector((state) => state.consultation)



    const params = useParams();
    const id = params.patientid

    useEffect(() => {
      dispatch(getFiltredConsultation(id))
    },  [dispatch]);

    
    
    
    return (
      <Fragment>
        <div className={style.patient_sidebar}>
          <div>
            <PatientCard />
          </div>


            {filtredconsultation.length > 0 ?
                <Fragment>
                    {filtredconsultation.map((el, i) => (
                      <div key={i}>
                        <Link to={`/dashboard/patients/${id}/consultation/${el.id}/`}>
                          <div
                            className={splitLocation[5] === `${el.id}` ? style.patient_links_active : style.patient_links }>
                              <div>
                                Consultation En Cours
                              </div>
                              <div className={style.encours} >
                                {el.date} {moment(el.start_time, "HH:mm").format('LT')}
                              </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </Fragment>
                :


                <Fragment>
                  <Link to={`/dashboard/patients/${id}/coordonnees`}>
                    <div 
                    className={splitLocation[4] === 'coordonnees' ? style.patient_links_active : style.patient_links }>
                          coordonnées
                    </div>
                  </Link>
                  {/* <Link to={`/dashboard/patients/${id}/taches`}>
                    <div
                    className={splitLocation[4] === 'taches' ? style.patient_links_active : style.patient_links }>
                          taches
                    </div>
                  </Link> */}
                  <Link to={`/dashboard/patients/${id}/documents`}>
                    <div
                    className={splitLocation[4] === 'documents' ? style.patient_links_active : style.patient_links }>
                          documents
                    </div>
                  </Link>
                  <Link to={`/dashboard/patients/${id}/historique`}>
                    <div 
                    className={splitLocation[4] === 'historique' ? style.patient_links_active : style.patient_links }>
                          historique
                    </div>
                  </Link>
                </Fragment>
            }



          <div className="patient_list">
            <PatientAntecedant />
          </div>
        </div>
    </Fragment>
  )
}
