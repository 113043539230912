import { Autocomplete, Button, FormControlLabel, IconButton, Paper, Switch, TextField } from '@mui/material'
import React, { Fragment, useEffect } from 'react'

import style from './AgnedaParameter.module.css'


import DeleteIcon from '@mui/icons-material/Delete';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EditIcon from '@mui/icons-material/Edit';
import { getMinute } from '../../../Components/Agenda/TableTime/Utils';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeSlot } from '../../../redux/features/Appoitment/AppoitmentSlice';
import UpdateTimeModal from '../../../Components/UpdateTimeModal/UpdateTimeModal';
import AddTimeForm from '../../../Components/AddTimeForm/AddTimeForm';


export default function AgnedaParameter() {

  const { timeslot } = useSelector((state) => state.appoitment);

  const dispatch = useDispatch()




  useEffect(() => {
    dispatch(getTimeSlot())
  }, [dispatch])



  
  const [modal, setModal] = useState(false);
  const [modifiedItems, setModifiedItems] = useState(null);
  const [addForm, setAddForm] = useState(false);



  const handleClickModify = (el) => {
    setModal(!modal)
    setModifiedItems(el)
  }


  const hadnleClickAdd = () => {
    setAddForm(!addForm)
  }


  return (
    <Fragment>
      <div className={style.container}>
        <Paper className={style.paper} >
          <div className={style.title} >
            <DateRangeIcon />
            <h2>Plages horaires</h2>
          </div>

          {timeslot && timeslot.map((el, i) => 
              <div key={i} className={style.ListTimeSlot}>
                <div className={style.ItemsInput}>
                    <TextField
                      style={{ marginBottom: "15px", background: "#fff" }}
                      sx={{ width: 130 }}
                      value={el.from_time}
                      variant="outlined"
                      fullWidth
                      disabled // Add the disabled prop to make the input disabled
                    />
                    <IconButton style={{ marginLeft : "15px", marginRight : "15px"}} aria-label="tiret">
                      <HorizontalRuleIcon />
                    </IconButton>
                    <TextField
                      style={{ marginBottom: "15px", background: "#fff" }}
                      sx={{ width: 130 }}
                      value={el.to_time}
                      variant="outlined"
                      fullWidth
                      disabled // Add the disabled prop to make the input disabled
                      />
                    <FormControlLabel
                      style={{ marginBottom: "15px", background: "#fff" }}
                      value="start"
                      control={<Switch 
                        color="primary" checked={el.available} />}
                      label={<span style={{ fontSize: '16px', marginLeft : "15px" }}>En ligne</span>}
                      labelPlacement="top"
                      disabled
                    />
                    <IconButton
                    onClick={() => handleClickModify(el)}
                    style={{ marginLeft : "15px", marginRight : "15px"}} aria-label="tiret">
                      <EditIcon />
                    </IconButton>
                </div>
              </div>
          )}


          {/* add form */}
          {addForm &&
            <AddTimeForm setAddForm={setAddForm} />
          }

          <div className={style.button}>
              <Button
              onClick={hadnleClickAdd}
              >Ajouté un creno</Button>
          </div>



        </Paper>
      </div>



      {modal &&
        <UpdateTimeModal modal={modal} setModal={setModal} modifiedItems={modifiedItems} setModifiedItems={setModifiedItems} />
      }
    </Fragment>
  )
}
