import { NaturePeopleOutlined } from '@mui/icons-material';
import React from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux';

import moment from 'moment';

import 'moment/locale/fr';

import style from './OrdonanceDetail.module.css'


export default function OrdonanceDetail({ componentRef, setPage }) {

    moment.locale('fr')

    const { prescriptiondetails } = useSelector((state) => state.prescription)





    return (
    <Fragment>
        <div ref={componentRef}>
            <div className={style.header}>
                <div className={style.doctor}>

                    <h2>Dr {prescriptiondetails && prescriptiondetails.created_by.nom} {prescriptiondetails && prescriptiondetails.created_by.prenom}</h2>
                    <h3>{prescriptiondetails && prescriptiondetails.created_by.specilite.nom}</h3>

                    <h3>{prescriptiondetails && prescriptiondetails.created_by.adresse}, &nbsp;
                        {prescriptiondetails && prescriptiondetails.created_by.location.code} &nbsp;
                        {prescriptiondetails && prescriptiondetails.created_by.location.ville}
                    </h3>
                </div>
                <div className={style.patient}>
                    
                    <h3>Le {prescriptiondetails && moment(prescriptiondetails.date).format("D MMMM y")}</h3>
                    <h2>M. {prescriptiondetails && prescriptiondetails.patient.nom}&nbsp;
                    {prescriptiondetails && prescriptiondetails.patient.prenom}
                    </h2>
                    <h3>Né le {prescriptiondetails && moment(prescriptiondetails.patient.date_de_naissance).format("DD-MM-YYYY")}</h3>
                </div>
            </div>
            <div className={style.label}>
                <h3>ordonnance</h3>
            </div>
            <div className={style.allmedoc}>

                {prescriptiondetails && prescriptiondetails.traitement.map((el, i) => 
                    <div key={i} className={style.medicaments}>
                        <h2>{el.medicament.denomination} {el.medicament.dosage} {el.medicament.forme}</h2>
                        <p>{el.posologie.usage}</p>
                    </div>
                )}
            </div>
        </div>
        <div className={style.buttons}>
            <button onClick={() => setPage(0)}>
                Retour
            </button>
        </div>
    </Fragment>
  )
}
