import style from './Home.module.css'
import img1 from '../../assets/images/Logo-doctodz-pro.png'

import { loginUser } from '../../redux/features/User/authSlice';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmailIcon from "@mui/icons-material/Email";


import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';




export default function Home() {

  const { isAuthenticated } = useSelector((state) => state.auth);


  const dispatch = useDispatch();
  const navigate = useNavigate()
  
  
  const [validation, setValidation] = useState("")

  const form = useRef()
  const email = useRef(null);
  const password = useRef(null);

  const [data, setData] = useState({
    email : null,
    password : null,
  })


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);



  const handleChangePassword = (elem) => {
    setData({...data, password : elem.target.value})
  }

  const handleChangeEmail = (email) => {
    setData({...data, email : email.target.value})
  }




  const handelSubmit = (e) => {
    e.preventDefault();
    try {
      dispatch(loginUser(data)).then((response) => {
        if (response.payload === 'cant connect') {
          setValidation('Une erreur est survenue. Veuillez vérifier votre compte et réessayer.')
        } else if (response.payload.access) {
          navigate('/dashboard/agenda/')
        }
      })
    } catch (error) {
      setValidation('Une erreur est survenue. Veuillez vérifier votre compte et réessayer.')
    }
  }

    return (
      <>
      <div className={style.content}>
        <div className={style.logo}>
        <img src={img1} alt="logo doctodz" />
        </div>

        <form
        onSubmit={handelSubmit}
        ref={form}
        >
          <div className={style.form}>
            <h1>J'ai déjà un compte Doctodz</h1>


            <TextField
                style={{ width : 400, marginBottom : "40px" }}
                className={style.input} 
                onChange={email => handleChangeEmail(email)}
                label="Votre adresse émail"
                InputProps={{
                  startAdornment: (
                    <EmailIcon />
                  ),
                }}
            >
            </TextField>


            <FormControl
                style={{ width : 400, marginBottom : "40px" }}
                className={style.input} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                    <OutlinedInput
                      onChange={(elem) => handleChangePassword(elem)}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Mot de passe"
                    />
            </FormControl>


            {validation &&
              <div className={style.danger}>
                <p>{validation}</p>
              </div>
            }
            <div className={style.btn}>
              <input type="submit" value="Se connecter"/>
            </div>
            <div className={style.link}>
            <Link to="/forgot-password/">Mot de passe oublié ?</Link>
              <div className={style.trait}></div>
            </div>
          </div>
        </form>
        <div className={style.caret}>
          <h3>Nouveau sur Doctodz ?</h3>
          <Link to="/singup">S'INSCRIRE</Link>
          {/* <Link to="/singup">S'INSCRIRE</Link> */}
        </div>
      </div>
    </>
  )

}
