import React, { Fragment } from 'react'
import style from './Cordonne.module.css';

export default function Cordonne({ formData, setFormData }) {

  const handleSubmit = e => e.preventDefault();

  const handleChangeEmail = (e) => {
    setFormData({...formData, email: e.target.value})
  }
  
  const handleChangePhone = (e) => {
    setFormData({...formData, phone_number: e.target.value})
  }



  return (
    <Fragment>
      <form
      onSubmit={handleSubmit}
      >
        <div className={style.inputs}>
          <div className={style.input}>
            <p>Email</p>
            <input
            onChange={handleChangeEmail}
            type="email" name="email" />
          </div>
          <div className={style.input}>
            <p>Numéro de téléphone</p>
            <input
            onChange={handleChangePhone}
            type="text" placeholder="Numéro de téléphone" />
          </div>
        </div>
      </form>
    </Fragment>
  )
}
