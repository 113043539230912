import React, { Fragment, useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAntecedant } from '../../redux/features/LifeStyle/LifeStyleSlice';
import { createReport, getConsultationOfcompte } from '../../redux/features/Report/ReportSlice';


import style from './ReportWrite.module.css'


export default function ReportWrite() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const patient_pk = params.patientid

  const { consultationcompte } = useSelector((state) => state.report)


  const [data, setData] = useState({
      antecedant : "",
      text : "",
      remarque : "",
      consultation : "",
      patient : patient_pk,
    })
    

  const [validation, setValidation] = useState("")



  const HandleSubmit = () => {
    if (data.antecedant && data.text && data.remarque && data.consultation && data.patient ) {
      setValidation(null)
        dispatch(createReport(data)).then((res) => {
          navigate(`/dashboard/patients/${patient_pk}/documents/`)
        })
    } else {
      setValidation('Veuillez Remplire tout les champs svp *') 
    }
  }





  useEffect(() => {
    dispatch(getConsultationOfcompte(patient_pk))
  },  [dispatch]);







  return (
    <Fragment>
      <div className={style.container}>
        <div className={style.form}>
          <div className={style.header}>
            <h2>Compte rendu</h2>
          </div>
          <div className={style.inputs}>
            <div className={style.input}>
              <p>Consultation</p>
              <select
              onChange={(e) => setData({...data, consultation : e.target.value})} 
              name="consultation">
                <option></option>
                {consultationcompte.length > 0 ? consultationcompte.map((el, i) =>
                  <option
                  value={el.id}
                  key={i} >Consultation du {el.date} A {el.start_time} </option>
                )
                :
                <option>Aucune consultation pour ce patient</option>
                }
              </select>
            </div>

            <div className={style.input}>
              <p>Antecedant</p>
              <textarea 
              value={data.antecedant}
              onChange={(e) => setData({...data, antecedant : e.target.value })}
              name="" id="" cols="30" rows="10">
              </textarea>
            </div>

            <div className={style.input}>
              <p>Rapport médecin</p>
              <textarea
              value={data.text}
              onChange={(e) => setData({...data, text : e.target.value})}
              name="" id="" cols="30" rows="10">
              </textarea>
            </div>

            <div className={style.input}>
              <p>Remarque</p>
              <textarea
              value={data.remarque}
              onChange={(e) => setData({...data, remarque : e.target.value})}
              name="" id="" cols="30" rows="10">
              </textarea>
            </div>
              
          <div className={style.validation}>
            <p>{validation}</p>
          </div>
          </div>
        </div>

        <div className={style.buttons}>
          <button>annuler</button>
          <button
          onClick={HandleSubmit}
          >Valider</button>
        </div>
      </div>
      
    </Fragment>
  )
}
