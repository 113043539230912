import { configureStore } from "@reduxjs/toolkit";

import auth from "./features/User/authSlice"
import patients from "./features/Patient/patientSlice"
import modeldata from "./features/ModelData/ModelDataSlice";
import antecedant from "./features/LifeStyle/LifeStyleSlice"
import prescription from "./features/Prescription/PrescriptionSlice"
import consultation from "./features/Consultation/ConsultationSlice"
import donnesuivie from './features/DonneeSuivie/DonneeSuivieSlice'
import appoitment from "./features/Appoitment/AppoitmentSlice";
import report from "./features/Report/ReportSlice";
import subscription from "./features/Subscription/SubscriptionSlice";

export default configureStore({
  reducer: {
    auth,
    patients,
    modeldata,
    antecedant,
    prescription,
    consultation,
    donnesuivie,
    appoitment,
    report,
    subscription,
  },
});