import React from 'react'
import { Fragment } from 'react-is'
import style from './CivilityForm.module.css'

export default function CivilityForm({ formData, setFormData }) {



  const handleSubmit = e => e.preventDefault();


  const handleChangeName = (e) => {
    setFormData({...formData, nom: e.target.value})
  }
  const handleChangeLastName = (e) => {
    setFormData({...formData, prenom: e.target.value})
  }
  const handleChangeSexe = (e) => {
    setFormData({...formData, sexe: e.target.value})
  }


  return (
    <Fragment>
      <div className={style.form}>
        <form
        onSubmit={handleSubmit}
        >
            <p>Sexe</p>
          <div className={style.sexe}>
            <input
            onChange={handleChangeSexe}
            type="radio" id="homme" name="sexe" value="homme" checked={formData.sexe === "homme"} />
            <label htmlFor="homme">Homme</label>
            <input
            onChange={handleChangeSexe}
            type="radio" id="femme" name="sexe" value="femme" checked={formData.sexe === "femme"} />
            <label htmlFor="homme">Femme</label>
          </div>
          <div className={style.names}>
              <div className="nom">
                  <p>Nom</p>
                  <input
                  value={formData.nom}
                  onChange={handleChangeName}
                  type="text" id="nom" placeholder="Nom" />
              </div>
              <div className={style.prenom}>
                  <p>Prénom</p>
                  <input
                  value={formData.prenom}
                  onChange={handleChangeLastName}
                  type="text" id="prenom" placeholder="Prénom" />
              </div>
          </div>
        </form>
      </div>
    </Fragment>
  )
}
