import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getReportForConsultation } from '../../redux/features/Report/ReportSlice'


import MedicationIcon from '@mui/icons-material/Medication';
import ShareIcon from '@mui/icons-material/Share';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import SummarizeIcon from '@mui/icons-material/Summarize';

import style from './ConsultationDetails.module.css'

export default function ConsultationDetails() {


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const patient_pk = params.patientid
  const consultation = params.consultationid

  const pcdata = {
    "patient_pk" : patient_pk,
    "consultation" : consultation
  }

  const { prescriptionforconsultation } = useSelector((state) => state.report);


  useEffect(() => {
    dispatch(getReportForConsultation(pcdata))
  },  [dispatch]);

  const handleClickItem = (item) => {
    if (item.type === "ordonance") {
      navigate(`/dashboard/patients/${patient_pk}/historique/consultation/${consultation}/ordonance/${item.id}/`)
    }
    else if (item.type === "compte rendu") {
      navigate(`/dashboard/patients/${patient_pk}/historique/consultation/${consultation}/compterendu/${item.id}/`)
    }
  }


  return (
    <Fragment>

          {prescriptionforconsultation && prescriptionforconsultation.map((item, i) => 
                  <div key={i} className={style.content}>
                  <div
                  onClick={() => handleClickItem(item)}
                  className={style.item}>
                    <div
                    className={style.it}>
                      {item.type === "ordonance" ? 
                        <MedicationIcon />
                        :
                        <SummarizeIcon />
                      }
                      <p>{item.type}</p>
                    </div>
                    <div className={style.it}>
                      <p>{item.date}</p>
                    </div>
                    <div className={style.it}>
                      <p>Dr {item.doctor}</p>
                    </div>
                    <div
                    className={style.it}>
                      <OpenInBrowserIcon />
                      <p>OUVRIR</p>
                    </div>
                  </div>
                </div>
          )}




    </Fragment>
  )
}
