import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const autoLogin = createAsyncThunk('auth/autoLogin',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const refreshToken = localStorage.getItem('refresh');
    if (refreshToken) {
      try {
        const res = await fetch('https://api.doctodz.com/api/account/token/refresh/', {
          method: 'POST',
          body: JSON.stringify({ refresh : refreshToken }),
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await res.json();
        if (res.status === 200) {
          localStorage.setItem("token", data.access)
          return data;
        } else if (res.status === 400 || res.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          return rejectWithValue('Token refresh expired');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      return rejectWithValue('Token refresh expired');
    }
  }
);


export const registerUSer = createAsyncThunk('auth/registerUSer',
  async (userData, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/account/proregister/', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await res.json();
      if (res.status === 201) {
        return fulfillWithValue('succes');
      } else if (res.status === 400 || res.status === 401) {
        return rejectWithValue("Invalid credentials");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const loginUser = createAsyncThunk('auth/loginUser',
    async (userData, thunkAPI) => {
      const {rejectWithValue, dispatch} = thunkAPI;
      try {
        const res = await fetch('https://api.doctodz.com/api/account/prologin/', {
          method: 'POST',
          body: JSON.stringify(userData),
          headers: { 'Content-Type': 'application/json; charset=utf'},
        });
        const data = await res.json();
        if (res.status === 200) {
          localStorage.setItem("token",data.access)
          localStorage.setItem("refresh",data.refresh)
          return data;
        } else if (res.status === 400 || res.status === 401) {
          return rejectWithValue('cant connect');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);



export const sendForgotPassword = createAsyncThunk('auth/sendForgotPassword',
    async (email, thunkAPI) => {
      const {rejectWithValue} = thunkAPI;
      try {
        const res = await fetch('https://api.doctodz.com/api/account/forget-password/', {
          method: 'POST',
          body: JSON.stringify(email),
          headers: { 'Content-Type': 'application/json; charset=utf'},
        });
        const data = await res.json();
        if (res.status === 200) {
          return data;
        } else if (res.status === 400 || res.status === 401) {
          return rejectWithValue('email not send');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);



export const changeForgotPassword = createAsyncThunk('auth/changeForgotPassword',
    async (dataa, thunkAPI) => {
      const {rejectWithValue} = thunkAPI;
      try {
        const res = await fetch('https://api.doctodz.com/api/account/change-forgot-password/', {
          method: 'POST',
          body: JSON.stringify(dataa),
          headers: { 'Content-Type': 'application/json; charset=utf'},
        });
        const data = await res.json();
        if (res.status === 200) {
          return data;
        } else if (res.status === 400 || res.status === 401) {
          return rejectWithValue('password not changed');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);


export const logoutUser = createAsyncThunk('auth/logoutUser',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      localStorage.removeItem("token")
      localStorage.removeItem("refresh")
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)


export const getCurrentUser = createAsyncThunk('auth/getCurrentUser',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/account/currentuser/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
)



const authSlice = createSlice({
    name: 'auth',
    initialState : {
        user: "",
        isAuthenticated : false,
        acceptAgree: false,
        isLoading: false,
        error: null,
    },
    reducers: {
      // agreeFunc: (state) => {
      //   state.acceptAgree = !state.acceptAgree;
      // },
    },
    extraReducers: {
        // loginUser user
        [loginUser.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [loginUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
        },
        [loginUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // auto login
        [autoLogin.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [autoLogin.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
        },
        [autoLogin.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // login user
        [logoutUser.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [logoutUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = false;
        },
        [logoutUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // send email ForgotPassword 
        [sendForgotPassword.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [sendForgotPassword.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [sendForgotPassword.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get current user
        [getCurrentUser.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [getCurrentUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.user = action.payload;
        },
        [getCurrentUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default authSlice.reducer;