import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'

import style from './OrdonanceMedicament.module.css'

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';


import { useDispatch, useSelector } from 'react-redux';
import { createPrescription, getMedicaments, getPosologie, sharePrescription } from '../../../../redux/features/Prescription/PrescriptionSlice';
import { useParams } from 'react-router-dom';

export default function OrdonanceMedicament({setPage}) {


  const { medicaments, posologies } = useSelector((state) => state.prescription)
  const dispatch = useDispatch()

  const params = useParams()
  const patient_pk = params.patientid
  const consultation_pk = params.consultationid


  const [toggleAdd, setToggleAdd] = useState(false)
  const [text, setText] = useState('')
  const [suggestion, setSuggestion] = useState([])

  const [textPoso, setTextPoso] = useState('')
  const [suggestionPoso, setSuggestionPoso] = useState([])

  const [medocList, setMedocList] = useState([])
  const [medoc, setMedoc] = useState({
    marque : '',
    dosage : '',
    forme : '',
    usage : '',
  })




  useEffect(() => {
    dispatch(getMedicaments())
    dispatch(getPosologie())
  },  [dispatch]);


  const onChangeHandler = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = medicaments.filter(medicament => {
        const regex = new RegExp(`${text}`, "gi");
        const allData = medicament.denomination.concat(medicament.marque);
        return allData.match(regex)
      })
    }
    setSuggestion(matches)
    setText(text)
  }


  const onSuggestHandler = (suggestion) => {
    if(suggestion) {
      setMedoc({...medoc, id: suggestion.id ,denomination : suggestion.denomination ,marque : suggestion.marque ,dosage : suggestion.dosage, forme : suggestion.forme })
      setText('')
      setSuggestion([])
      setToggleAdd(true)
    }
  }

  
  const posoChangeHandler = (e) => {
    setMedoc({...medoc, usage: e.target.value})
  }


  const annulerHandler = () => {
    setToggleAdd(false)
    setMedoc({})
  }

  const validerHandler = () => {
    setMedocList([...medocList, medoc])
    setToggleAdd(false)
    setTextPoso('')
  }


  const onChangeHandlerPoso = (textPoso) => {
    let matchesPoso = []
    if (textPoso.length > 0) {
      matchesPoso = posologies.filter(posologie => {
        const regex = new RegExp(`${textPoso}`, "gi");
        return posologie.usage.match(regex)
      })
    }
    setSuggestionPoso(matchesPoso)
    setTextPoso(textPoso)
  }

  const onSuggestHandlerPoso = (suggestionPoso) => {
    if(suggestionPoso) {
      setMedoc({...medoc, usage: suggestionPoso.usage})
      setTextPoso(suggestionPoso.usage)
      setSuggestionPoso([])
    }
  }

  const handleDeleteMedoc = (item, i) => {
    if(item) {
      const allData = [...medocList]
      allData.splice(i, 1)
      setMedocList(allData)
    }
  }


  const listData = medocList.map((item , i) => (
    <div key={i} className={style.liste}>
      <div className={style.text}>
        <h4>{item.marque} {item.dosage} {item.forme}</h4>
        <p>{item.usage}</p>
      </div>
      <div onClick={() => handleDeleteMedoc(item, i)} className={style.icon} >
        <ClearIcon style={{fontSize: "24px"}} />
      </div>
    </div>
  ))


  const handleSaveOrdo = () => {
    const ordoData = {
      patient : patient_pk,
      consultation : consultation_pk,
      traitement : medocList
    }
    dispatch(createPrescription(ordoData)).then(() => {
      setPage(0)
    })
  }



  const handleSaveOrdoShare = () => {
    const ordoData = {
      patient : patient_pk,
      consultation : consultation_pk,
      traitement : medocList
    }
    dispatch(createPrescription(ordoData)).then((data) => {
        const presobj = {
          "consultation" : data.payload.consultation.id,
          "patient" : data.payload.patient.id,
          "prescription" : data.payload.id
        }
        dispatch(sharePrescription(presobj)).then(() => {
          setPage(0)
        })
    })
  }



  return (
    <Fragment>
      <div className={style.head}>
        <div className={style.search_space}>
          <div className={style.search}>
            <SearchIcon style={{fontSize: "30px"}} className={style.serachicon} />
            <input
            value={text}
            onChange={e => onChangeHandler(e.target.value)}
            type="text" placeholder="Ajouter un médicament ou un modèle" />
          </div>
          <div className={style.allsuggestion}>
            {suggestion && suggestion.map((suggestion, i) => 
              <div
              className={style.suggestion}
              onClick={() => onSuggestHandler(suggestion)}
              key={i}>
                {suggestion.marque} / {suggestion.dosage} / {suggestion.forme}
              </div>
            )}
          </div>
          {toggleAdd &&
            <div className={style.add_item}>
              <div className={style.input_container}>
                  <h2>{medoc.marque} / {medoc.dosage} / {medoc.forme}</h2>
                <div
                  onChange={posoChangeHandler}
                  className={style.input}>
                    <input
                    value={textPoso}
                    onChange={e => onChangeHandlerPoso(e.target.value)}
                    type="text" placeholder="Saisir une posologie" />
                </div>

                <div className={style.allsuggestion2}>

                {suggestionPoso && suggestionPoso.map((suggestionPoso, i) => 
                  <div
                    className={style.suggestion2}
                    onClick={() => onSuggestHandlerPoso(suggestionPoso)}
                    key={i}>
                    {suggestionPoso.usage}
                  </div>
                )}
                </div> 
              </div>





              <div className={style.buttons1}>
                <button
                onClick={annulerHandler}
                >Annuler</button>
                <button
                onClick={validerHandler}
                >Valider</button>
              </div>
            </div>

          }
        </div>

        <div className={style.listes}>
              {listData && listData}
        </div>
      </div>
      
      <div className={style.buttons}>
        <button onClick={() => setPage(0)}>Annuler</button>
        <button
        onClick={handleSaveOrdoShare}
        >Créer et partager</button>
        <button
        onClick={handleSaveOrdo}
        >Créer</button>
      </div>
    </Fragment>
  )
}
