import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const getSubscriptionList = createAsyncThunk('subscription/getSubscriptionList',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch("https://api.doctodz.com/api/souscription/modules/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf',
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



const SubscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        subscriptionlist: [],
        isLoading: false,
        CallModal: false,
        RdvModal: false,
        error: null,
    },
    reducers: {
      setCallModal: (state) => {
        state.CallModal = !state.CallModal;
      },
      setRdvModal: (state) => {
        state.RdvModal = !state.RdvModal;
      },
    },
    extraReducers: {
    // doctor share report with patient
    [getSubscriptionList.pending]: (state, action) => {
        state.isLoading = true;
        state.error = null;
    },
    [getSubscriptionList.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.subscriptionlist = action.payload;
    },
    [getSubscriptionList.rejected]: (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
    },
    }
});


export const { setCallModal, setRdvModal } = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;




